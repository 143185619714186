import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { banks } from "../../global/banks";
import { useDispatch, useSelector } from "react-redux";
import { formatDollar } from "../../global/formatDollar";
import api from "../../api/api";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import toast from "react-hot-toast";
import { fetchAccounts } from "../../features/accountSlice";
import { fetchTransactions } from "../../features/transactionSlice";
import AccountTransfers from "../../components/transaction/AccountTransfers";
import PinModal from "../../components/pin/PinModal";

const TransferPage = () => {
  const accounts = useSelector((state) => state.account.items);
  const user = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const [transferType, setTransferType] = useState("same-bank");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    account_id: "",
    recipient_account: "",
    amount: "",
    bank_name: "",
    routing_number: "",
    description: "",
  });
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState("");

  const handleTransferTypeChange = (type) => {
    setTransferType(type);
    setFormData((prevData) => ({
      ...prevData,
      bank_name: "",
      routing_number: "",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { account_id, recipient_account, amount, bank_name, routing_number } =
      formData;

    if (!account_id || !recipient_account || !amount) {
      return "Please fill all required fields.";
    }

    if (transferType === "other-bank" && (!bank_name || !routing_number)) {
      return "Please provide the bank name and routing number for other bank transfers.";
    }

    return null;
  };

  const handlePinSubmit = async () => {
    if (pin !== user.pin) {
      toast.error("Incorrect PIN. Please try again.");
      return;
    }
    setShowPinModal(false); // Close PIN modal
    setLoading(true);

    try {
      const res = await api.post("/transactions/transfer", formData);
      toast.success(res.data.message);
      dispatch(fetchAccounts());
      dispatch(fetchTransactions());

      setFormData({
        account_id: "",
        recipient_account: "",
        amount: "",
        bank_name: "",
        routing_number: "",
        description: "",
      });
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      }
    } finally {
      setLoading(false);
      setPin("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    setShowPinModal(true);
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Transfer Overview</h2>
        <div className="w-full flex max-lg:flex-col lg:items-start gap-5 mt-5">
          <div className={GlobalStyle.sectionContainer}>
            <h2 className={GlobalStyle.textPrimaryBold}>Make Transfer</h2>
            <form
              className="w-full flex flex-col gap-4"
              onSubmit={handleSubmit}
            >
              <select
                className={GlobalStyle.input2}
                name="account_id"
                value={formData.account_id}
                onChange={handleInputChange}
              >
                <option value="">Send from</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.account_type} account({account.account_number}){" "}
                    {formatDollar(account.balance)}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className={GlobalStyle.input}
                name="recipient_account"
                value={formData.recipient_account}
                onChange={handleInputChange}
                placeholder="Account number"
              />
              <input
                type="number"
                className={GlobalStyle.input}
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                placeholder="Amount"
              />
              <div className="flex gap-5 items-center">
                <button
                  type="button"
                  className={`px-4 py-2 rounded-3xl text-sm transition-all ease-in-out duration-1000 ${
                    transferType === "same-bank"
                      ? "bg-primary text-white scale-100"
                      : "bg-primary/10 text-primary scale-90"
                  }`}
                  onClick={() => handleTransferTypeChange("same-bank")}
                >
                  Same Bank
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 rounded-3xl text-sm transition-all ease-in-out duration-1000 ${
                    transferType === "other-bank"
                      ? "bg-primary text-white scale-100"
                      : "bg-primary/10 text-primary scale-90"
                  }`}
                  onClick={() => handleTransferTypeChange("other-bank")}
                >
                  Other Banks
                </button>
              </div>
              {transferType === "other-bank" && (
                <>
                  <select
                    className={GlobalStyle.input}
                    name="bank_name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a bank</option>
                    {banks.map((bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    className={GlobalStyle.input}
                    name="routing_number"
                    value={formData.routing_number}
                    onChange={handleInputChange}
                    placeholder="Routing number"
                  />
                </>
              )}
              <input
                type="text"
                className={GlobalStyle.input}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description (optional)"
              />

              <button
                className={GlobalStyle.buttonFull}
                disabled={loading}
                type="submit"
              >
                {loading ? <LoadingSpinner /> : "Continue"}
              </button>
            </form>
          </div>
          <AccountTransfers />
        </div>
      </div>

      {showPinModal && (
        <PinModal
          pin={pin}
          setPin={setPin}
          onSubmit={handlePinSubmit}
          onCancel={() => setShowPinModal(false)}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TransferPage;
