import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import BasicInfo from "../../components/manageUser/BasicInfo";
import AddAccount from "../../components/manageUser/AddAccount";
import UsersAccounts from "../../components/manageUser/UsersAccounts";
import UserTransaction from "../../components/manageUser/UserTransaction";
import MakeChanges from "../../components/manageUser/MakeChanges";

const SingleUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getUser = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/view-user/${id}`);
      setUser(res.data.user);
      setAccounts(res.data.accounts);
      setTransactions(res.data.transactions);
    } catch (error) {
      toast.error("Error fetching user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className={GlobalStyle.containerAdmin}>
        <div className={GlobalStyle.adminWrap2}>
          <div className="w-full h-full flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={GlobalStyle.containerAdmin}>
      {user && (
        <div className={GlobalStyle.adminWrap2}>
          <h2 className={GlobalStyle.header}>
            Manage {user.firstname} {user.lastname}
          </h2>
          <div className="w-full mt-5 flex max-lg:flex-col gap-5">
            <BasicInfo user={user} />
            <AddAccount user={user} getUser={getUser} />
          </div>
          <div className="w-full mt-5">
            <MakeChanges user={user} getUser={getUser} />
          </div>
          <div className="w-full mt-5">
            <UsersAccounts
              user={user}
              loading={loading}
              accounts={accounts}
              getUser={getUser}
            />
          </div>
          <div className="w-full mt-5">
            <UserTransaction
              user={user}
              loading={loading}
              accounts={accounts}
              transactions={transactions}
              getUser={getUser}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleUser;
