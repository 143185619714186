import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { RxArrowBottomRight, RxArrowTopRight } from "react-icons/rx";
import { formatDollar } from "../../global/formatDollar";
import api from "../../api/api";
import toast from "react-hot-toast";
import { maskSensitiveData } from "../../global/maskSensitiveData";

const UserTransaction = ({ transactions, getUser }) => {
  const [filter, setFilter] = useState("default");
  const [search, setSearch] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const changeStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(`/change-status/${transaction.id}`, {
        status,
      });
      toast.success(res.data.message);
      getUser();
      closeModal();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (transaction) {
      setStatus(transaction.status);
    }
  }, [transaction]);

  const formatTransactionType = (type) => {
    return type === "card_payment" ? "Card Payment" : "Wire Transfer";
  };

  const handleSortChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const filteredTransactions = transactions
    .filter((item) => {
      if (filter === "cards" && !item.card_id) return false;
      if (filter === "account" && !item.account_id) return false;
      return true;
    })
    .filter((item) =>
      item.transaction_reference.toLowerCase().includes(search.trim())
    )
    .slice()
    .reverse();

  const groupedTransactions = filteredTransactions.reduce((groups, item) => {
    const date = new Date(item.created_at);
    const monthYear = `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
    if (!groups[monthYear]) groups[monthYear] = [];
    groups[monthYear].push(item);
    return groups;
  }, {});

  const showTransaction = (item) => {
    setTransaction(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTransaction(null);
  };

  return (
    <div className={GlobalStyle.transactionContainer}>
      <div className={GlobalStyle.transactionContainerWrap}>
        <div className="w-full flex max-md:flex-col md:items-center max-md:gap-2 justify-between">
          <h3 className={GlobalStyle.textPrimaryBold}>Transactions</h3>
          <div className="flex gap-1 items-center">
            <span className="text-xs text-primary">Sort by:</span>
            <select
              className={GlobalStyle.inputSmall}
              value={filter}
              onChange={handleSortChange}
            >
              <option value="default">Default</option>
              <option value="cards">Cards</option>
              <option value="account">Accounts</option>
            </select>
          </div>
        </div>
        <input
          type="text"
          className={GlobalStyle.input}
          placeholder="Search by reference"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <div className="overflow-y-auto max-h-[470px] flex flex-col gap-3 mt-3">
        {Object.entries(groupedTransactions).map(([monthYear, items]) => (
          <div key={monthYear}>
            <h4 className={GlobalStyle.textSmall}>{monthYear}</h4>
            <div className="w-full flex flex-col gap-1">
              {items.map((item) => (
                <button
                  key={item.id}
                  onClick={() => showTransaction(item)}
                  className="w-full py-1 px-3 rounded-3xl bg-primary/10 grid grid-cols-3 text-xs md:text-sm"
                >
                  <div className="w-full h-full flex items-center gap-1 text-primary">
                    {item.direction === "outgoing" ? (
                      <RxArrowTopRight className="text-xl text-red-500" />
                    ) : (
                      <RxArrowBottomRight className="text-xl text-green-500" />
                    )}
                    <span>{formatTransactionType(item.transaction_type)}</span>
                  </div>
                  <div className={GlobalStyle.textTransaction}>
                    {formatDollar(item.amount)}
                  </div>
                  <div className="w-full flex items-center justify-end">
                    <div className={GlobalStyle.status}>{item.status}</div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {showModal && transaction && (
        <div className={GlobalStyle.modal}>
          <div className={GlobalStyle.transactionModalContainer}>
            <h3 className={GlobalStyle.textPrimaryBold}>Transaction details</h3>
            <span className="text-lg text-gray-500 pt-2">
              {transaction.direction === "outgoing" ? "-" : "+"}{" "}
              {formatDollar(transaction.amount)}
            </span>
            <span className={GlobalStyle.status}>{transaction.status}</span>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Type:</span>
              <span>{formatTransactionType(transaction.transaction_type)}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Reference ID:</span>
              <span>{transaction.transaction_reference}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Date:</span>
              <span>
                {new Date(transaction.created_at).toLocaleDateString()}
              </span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Time:</span>
              <span>
                {new Date(transaction.created_at).toLocaleTimeString()}
              </span>
            </div>
            {transaction.transaction_type === "transfer" && (
              <div className={GlobalStyle.transactionModalColumn}>
                <span>From:</span>
                <span>{maskSensitiveData(transaction.senders_account)}</span>
              </div>
            )}
            {transaction.account_id !== null &&
              transaction.card_id === null && (
                <>
                  <div className={GlobalStyle.transactionModalColumn}>
                    <span>Bank:</span>
                    <span>{transaction.bank_name}</span>
                  </div>
                  <div className={GlobalStyle.transactionModalColumn}>
                    <span>To:</span>
                    <span>{transaction.recipient_account}</span>
                  </div>
                  <div className={GlobalStyle.transactionModalColumn}>
                    <span>Routing number:</span>
                    <span>{transaction.routing_number}</span>
                  </div>
                </>
              )}
            {transaction.description !== null && (
              <div className={GlobalStyle.transactionModalColumn}>
                <span>Description:</span>
                <span>{transaction.description}</span>
              </div>
            )}
            <div className="w-full text-center">
              <h4 className={GlobalStyle.textPrimaryBold}>Change status</h4>
            </div>
            <form onSubmit={changeStatus} className="w-full flex gap-5">
              <select
                className={GlobalStyle.input}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="pending">Pending</option>
                <option value="success">Success</option>
                <option value="failed">Failed</option>
              </select>
              <button className={GlobalStyle.buttonFull}>
                {loading ? "Chainging..." : "Change"}
              </button>
            </form>
            <button className={GlobalStyle.buttonFull} onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTransaction;
