import React, { useState } from "react";
import { PiCaretRightThin } from "react-icons/pi";
import GlobalStyle from "../../../global/GlobalStyle";
import savings from "../../../images/homeImages/savings.png";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "How is the interest calculated?",
    answer:
      "Interest is compounded monthly based on your average daily balance.",
  },
  {
    question: "Is there a limit on the number of withdrawals?",
    answer:
      "Federal regulations allow up to 6 withdrawals per statement cycle.",
  },
];

const SavingsAccounts = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <Link to="/accounts">Accounts</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Savings Account</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Savings Accounts</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Manage your savings effortlessly with Crestwell Bank Savings
            Accounts.
          </span>
          <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
            <img
              data-aos="fade-right"
              data-aos-duration="1000"
              src={savings}
              alt="Savings Account"
              className="w-full shadow-lg"
            />
            <div className="w-full flex flex-col gap-5">
              <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>Competitive interest rates</li>
                <li className={GlobalStyle.text}>
                  Easy transfers between accounts
                </li>
                <li className={GlobalStyle.text}>
                  No minimum balance requirement
                </li>
                <li className={GlobalStyle.text}>
                  Secure online and mobile access
                </li>
                <li className={GlobalStyle.text}>
                  Quarterly statements for financial planning
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>How It Works</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Complete the online application in minutes
                </li>
                <li className={GlobalStyle.text}>
                  Verify your identity with a valid ID
                </li>
                <li className={GlobalStyle.text}>
                  Make an initial deposit (minimum $25)
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <div className="w-full text-center my-2">
              <h4 className={GlobalStyle.textPrimaryBold}>FAQ's</h4>
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b mb-4">
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left p-4 text-sm font-medium focus:outline-none flex justify-between items-center ${
                    activeIndex === index ? "text-primary" : "text-gray-500"
                  }`}
                >
                  <span>{faq.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="p-4 text-gray-600 text-xs">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <p className={GlobalStyle.textSmallItalic}>
            Start saving today and secure your financial future with Crestwell
            Bank.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SavingsAccounts;
