import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { formatDollar } from "../../global/formatDollar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DashboardStats = () => {
  const stats = useSelector((state) => state.stats.items);
  return (
    <div className={GlobalStyle.sectionContainer}>
      <div className="w-full">
        <h3 className={GlobalStyle.textPrimaryBold}>
          Your statistics this month
        </h3>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-3">
        <div className="w-full max-lg:py-3 lg:p-3 flex flex-col gap-1">
          <h4 className={GlobalStyle.textSmallPrimary}>Total Income</h4>
          <span className={GlobalStyle.moneyText}>
            {formatDollar(parseFloat(stats.incoming_sum))}
          </span>
        </div>
        <div className="w-full max-lg:py-3 lg:p-3 flex flex-col gap-1 max-lg:border-y lg:border-x border-primary/20 border-dashed">
          <h4 className={GlobalStyle.textSmallPrimary}>Total Spending</h4>
          <span className={GlobalStyle.moneyText}>
            {formatDollar(stats.outgoing_sum)}
          </span>
        </div>
        <div className="w-full max-lg:py-3 lg:p-3 flex flex-col gap-1">
          <h4 className={GlobalStyle.textSmallPrimary}>Total Transactions</h4>
          <span className={GlobalStyle.moneyText}>
            {formatDollar(stats.total_sum)}
          </span>
        </div>
      </div>
      <Link
        to={"/online-banking/transactions"}
        className={GlobalStyle.buttonFull}
      >
        View transactions
      </Link>
    </div>
  );
};

export default DashboardStats;
