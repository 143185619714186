import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import onlineBanking from "../../images/homeImages/mobile.png";
import cc from "../../images/homeImages/cc.png";
import transfer from "../../images/homeImages/Success.png";
import history from "../../images/homeImages/history.png";
import support from "../../images/homeImages/support.png";

const servicesOffered = [
  {
    service: "Online Banking",
    description:
      "Access your accounts anytime, anywhere. Monitor balances, transfer funds, and pay bills securely from any device.",
    benefits: [
      "Convenient 24/7 access to your accounts.",
      "Easy bill payments and fund transfers.",
      "Secure and user-friendly interface.",
    ],
    image: onlineBanking,
  },
  {
    service: "Debit and Credit Cards",
    description:
      "Shop, dine, and pay seamlessly with Clyveris’s debit and credit cards. Enjoy financial flexibility and exclusive rewards.",
    benefits: [
      "Secure transactions worldwide.",
      "Contactless payment options for faster checkouts.",
      "Exclusive cashback and rewards on every spend.",
    ],
    image: cc,
  },
  {
    service: "Fund Transfers",
    description:
      "Move your money easily across accounts, whether it’s within Clyveris Bank or to other banks, locally or internationally.",
    benefits: [
      "Real-time transfers for Clyveris accounts.",
      "Secure interbank and international transfers.",
      "Intuitive tracking and notifications for every transaction.",
    ],
    image: transfer,
  },
  {
    service: "Account Statements",
    description:
      "Keep track of your financial activity with detailed account statements available online or on demand.",
    benefits: [
      "Monthly digital statements sent to your email.",
      "Comprehensive transaction history for better budgeting.",
      "Easy downloads for record-keeping.",
    ],
    image: history,
  },
  {
    service: "24/7 Customer Support",
    description:
      "Our dedicated support team is here to help with any queries or issues, anytime you need assistance.",
    benefits: [
      "Round-the-clock service via chat, phone, or email.",
      "Quick resolutions for any banking concerns.",
      "Personalized assistance tailored to your needs.",
    ],
    image: support,
  },
];

const SectionFive = () => {
  return (
    <div className="w-full min-h-screen bg-default bg-fixed bg-cover bg-no-repeat max-lg:bg-right pt-20">
      <div className="w-full h-full flex flex-col p-10 items-center gap-10">
        <h2 className={GlobalStyle.header}>
          Banking Made Easy with Clyveris Bank.
        </h2>
        <p className={GlobalStyle.textSmallItalic}>
          We provide a suite of services to simplify your financial life and
          meet your everyday banking needs.
        </p>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {servicesOffered.map((item) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="w-full p-5 bg-white shadow flex max-lg:flex-col gap-3"
              >
                <div className="max-lg:w-full lg:h-full flex items-center justify-center">
                  <img
                    src={item.image}
                    alt="null"
                    className="w-28 aspect-[1/1]"
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <div className="w-full text-center">
                    <h3 className={GlobalStyle.textPrimaryBold}>
                      {item.service}
                    </h3>
                  </div>
                  <p className={GlobalStyle.textSmallItalic}>
                    {item.description}
                  </p>
                  <ul className="list-decimal list-inside">
                    {item.benefits.map((benefit) => (
                      <li className={GlobalStyle.textSmall} key={benefit}>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
