import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import toast from "react-hot-toast";
import api from "../../api/api";

const AddAccount = ({ user, getUser }) => {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const data = {
    account_type: type,
    user_id: user.id,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/accounts", data);
      toast.success("Account created successfully");
      setInfo(res.data.account);
      setType("");
      getUser();
      setTimeout(() => {
        setInfo("");
      }, 10000);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={GlobalStyle.sectionContainer2}>
      <div className="w-full flex flex-col gap-2">
        <h3 className={GlobalStyle.textPrimaryBold}>Create account</h3>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full gap-2 text-primary"
        >
          <span className={GlobalStyle.textSmall}>
            Create a new account for {user.firstname}
          </span>
          <select
            type="text"
            className={GlobalStyle.input}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Select account type</option>
            <option value="checking">Checking</option>
            <option value="savings">Savings</option>
          </select>
          <button className={GlobalStyle.buttonFull}>
            {loading ? "Processing..." : "Create"}
          </button>
        </form>
        {info && (
          <span className={GlobalStyle.textSmallPrimary}>
            A {info.account_type} account with account number{" "}
            {info.account_number} has been created for {user.firstname}.
          </span>
        )}
      </div>
    </div>
  );
};

export default AddAccount;
