import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import Card from "../../images/homeImages/card.png";
import Success from "../../images/homeImages/Success.png";
import Deposit from "../../images/homeImages/deposit.png";
import Signup from "../../images/homeImages/signup.png";

const features = [
  {
    title: "Open an Account",
    image: Signup, // Replace with an actual image URL
    description:
      "Creating an account with Clyveris Bank is quick and straightforward. Choose between Checking or Savings accounts, and enjoy tailored benefits suited to your financial goals.",
    steps: [
      "Provide your basic information (name, email, etc.).",
      "Choose your account type.",
      "Set up your secure Banking ID and PIN.",
    ],
    tagline: "Your journey to better banking starts here.",
  },
  {
    title: "Fund Your Account",
    image: Deposit, // Replace with an actual image URL
    description:
      "Deposit funds easily through bank transfers, debit cards, or linked accounts. Get instant access to your balance for transactions or savings.",
    steps: [
      "Use our secure online platform to link an external account.",
      "Deposit cash via our partnered ATMs or through direct deposit.",
      "View transaction details in real time.",
    ],
    tagline: "Funding your goals has never been easier.",
  },
  {
    title: "Access Your Cards",
    image: Card, // Replace with an actual image URL
    description:
      "Receive your Clyveris Debit or Credit Card after account creation. Enjoy features like contactless payments, secure online shopping, and worldwide acceptance.",
    steps: [
      "Choose your card type during account setup or apply later.",
      "Track your card delivery through the app.",
      "Activate your card with a PIN and start spending!",
    ],
    tagline: "Your money, your way, anytime.",
  },
  {
    title: "Make Transactions Anywhere",
    image: Success, // Replace with an actual image URL
    description:
      "Send money, pay bills, or shop online—all within a few taps. Whether you're transferring to a Clyveris account or another bank, we've got you covered.",
    steps: [
      'Use the dashboard to select "Transfer Funds" or "Pay Bills."',
      "Enter the recipient’s details or bill information.",
      "Confirm your transaction and receive instant notifications.",
    ],
    tagline: "Control your finances from anywhere in the world.",
  },
];

const SectionThree = () => {
  const [currentFeature, setCurrentFeature] = useState(0);

  const handleFeatureChange = (index) => {
    setCurrentFeature(index);
  };

  const { title, image, description, steps, tagline } =
    features[currentFeature];
  return (
    <div className="w-full min-h-screen bg-white pt-20">
      <div className="w-full h-full flex flex-col p-10 items-center gap-10">
        <h2 className={GlobalStyle.header}>
          Simple Steps to Manage Your Finances.
        </h2>
        <p className={GlobalStyle.textSmallItalic}>
          Experience seamless banking in just a few steps. Here's how you can
          get started with Clyveris Bank.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4">
          {features.map((feature, index) => (
            <button
              key={index}
              onClick={() => handleFeatureChange(index)}
              className={`px-2 py-2 transition-all ease-in-out duration-1000 border-b text-xs hover:text-primary ${
                currentFeature === index
                  ? "text-primary border-primary bg-white"
                  : "text-gray-500"
              }`}
            >
              {feature.title}
            </button>
          ))}
        </div>
        <div className="flex max-lg:flex-col max-lg:items-center">
          <div className="w-full lg:w-1/2 p-5">
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              src={image}
              alt={title}
              className="w-full h-auto"
            />
          </div>
          <div className="w-full p-5 flex flex-col gap-2">
            <h2 className={GlobalStyle.header}>{title}</h2>
            <p className={GlobalStyle.text}>{description}</p>
            <h3 className={GlobalStyle.textPrimaryBold}>Step-by-Step:</h3>
            <ul className="list-disc pl-5 mb-4">
              {steps.map((step, index) => (
                <li className={GlobalStyle.text} key={index}>
                  {step}
                </li>
              ))}
            </ul>
            <p className="italic text-primary">{tagline}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
