import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/home">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Privacy Policy</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Privacy Policy</h1>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <span className={GlobalStyle.text}>
              Welcome to Clyveris Bank. Your privacy is of utmost importance to
              us. This Privacy Policy outlines how we collect, use, protect, and
              share your personal information when you interact with our
              website, mobile applications, and services.
            </span>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Information We Collect</h2>
              <p className={GlobalStyle.text}>
                We collect the following types of information:
              </p>
              <h3 className={GlobalStyle.textPrimaryBold}>
                Personal Information
              </h3>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Name, address, email, phone number
                </li>
                <li className={GlobalStyle.text}>
                  Banking ID and account details
                </li>
                <li className={GlobalStyle.text}>
                  Card information (for transactions)
                </li>
                <li className={GlobalStyle.text}>
                  Social Security Number (where applicable)
                </li>
              </ul>
              <h3 className={GlobalStyle.textPrimaryBold}>Usage Information</h3>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  IP address, browser type, and device information
                </li>
                <li className={GlobalStyle.text}>
                  Interaction with our website and mobile applications
                </li>
                <li className={GlobalStyle.text}>Cookies and tracking data</li>
              </ul>

              <h3 className={GlobalStyle.textPrimaryBold}>
                Third-Party Information
              </h3>
              <p className={GlobalStyle.text}>
                We may collect data from third parties such as credit reporting
                agencies or payment processors.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                How We Use Your Information
              </h2>
              <p className={GlobalStyle.text}>We use your information to:</p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Provide and improve our banking services
                </li>
                <li className={GlobalStyle.text}>
                  Process transactions and manage accounts
                </li>
                <li className={GlobalStyle.text}>
                  Communicate with you about updates, offers, and alerts
                </li>
                <li className={GlobalStyle.text}>
                  Comply with legal and regulatory requirements
                </li>
                <li className={GlobalStyle.text}>
                  Prevent fraud, unauthorized access, or security breaches
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Sharing Your Information
              </h2>
              <p className={GlobalStyle.text}>
                We do not sell your information. However, we may share your data
                in the following circumstances:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  With Service Providers: To process payments, detect fraud, and
                  manage communications.
                </li>
                <li className={GlobalStyle.text}>
                  With Regulatory Authorities: To comply with legal
                  requirements.
                </li>
                <li className={GlobalStyle.text}>
                  With Your Consent: When you authorize us to share your
                  information.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Data Security</h2>
              <p className={GlobalStyle.text}>
                We implement robust security measures to protect your data,
                including:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Encryption of sensitive data.
                </li>
                <li className={GlobalStyle.text}>Regular security audits.</li>
                <li className={GlobalStyle.text}>
                  Restricted access to your information.
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                However, no system is completely secure. Please safeguard your
                passwords and account details.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Your Rights</h2>
              <p className={GlobalStyle.text}>
                You have the following rights regarding your data:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Access: Request access to the data we hold about you.
                </li>
                <li className={GlobalStyle.text}>
                  Correction: Update or correct inaccurate information.
                </li>
                <li className={GlobalStyle.text}>
                  Deletion: Request deletion of your data, subject to legal
                  obligations.
                </li>
                <li className={GlobalStyle.text}>
                  Opt-Out: Manage marketing preferences or withdraw consent for
                  data processing.
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                To exercise these rights, contact us at [Insert Contact Email].
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Cookies Policy</h2>
              <p className={GlobalStyle.text}>
                We use cookies to enhance your browsing experience. Cookies
                allow us to:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>Recognize your preferences</li>
                <li className={GlobalStyle.text}>Analyze website traffic</li>
                <li className={GlobalStyle.text}>
                  Provide personalized content
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                You can disable cookies in your browser settings, but doing so
                may limit certain features of our services.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Third-Party Links</h2>
              <p className={GlobalStyle.text}>
                Our website may contain links to third-party websites. We are
                not responsible for their privacy practices or content. Please
                review their privacy policies before providing personal
                information.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Updates to This Policy</h2>
              <p className={GlobalStyle.text}>
                We may update this Privacy Policy to reflect changes in our
                practices or legal requirements. Any updates will be posted on
                this page with the revised effective date.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Contact Us</h2>
              <p className={GlobalStyle.text}>
                If you have questions or concerns about this Privacy Policy,
                contact us at: Email: support@clyverisbank.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
