import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";

const faqs = [
  {
    question: "How do I open an account with Clyveris Bank?",
    answer:
      "Opening an account is simple! You can start by signing up online through our secure platform. Choose between a checking or savings account, complete the required information, and you're ready to go.",
  },
  {
    question: "What types of accounts do you offer?",
    answer:
      "We currently offer two account types: Checking Account (ideal for daily transactions) and Savings Account (perfect for saving money while earning interest).",
  },
  {
    question: "How can I transfer funds to another bank?",
    answer:
      'Log into your account, select "Fund Transfer," and provide the recipient\'s bank name, account number, and routing number. You’ll receive a confirmation once the transfer is successful.',
  },
  {
    question: "How do I get a debit or credit card?",
    answer:
      "Once your account is active, you can request a debit or credit card through your dashboard. Cards are processed within 5–7 business days and delivered to your registered address.",
  },
  {
    question: "What should I do if I forget my PIN?",
    answer:
      'You can reset your PIN via the online banking portal. Navigate to "Settings," select "Reset PIN," and follow the instructions. If you face any issues, our support team is available 24/7.',
  },
  {
    question: "How secure is online banking with Clyveris Bank?",
    answer:
      "Your security is our top priority. We use advanced encryption technology, two-factor authentication, and real-time monitoring to keep your account safe.",
  },
  {
    question: "Can I access my account statements online?",
    answer:
      'Yes, account statements are available in your dashboard under the "Statements" section. You can view, download, and print your statements anytime.',
  },
  {
    question: "How do I contact customer support?",
    answer:
      "You can reach us through live chat on our website, call us at 1-800-555-1234, or email us at Support@clyverisbank.com.",
  },
];

const SectionSix = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 pt-20">
      <div className="w-full h-full flex flex-col p-10 items-center gap-10">
        <h2 className={GlobalStyle.header}>Get Answers to Your Questions.</h2>
        <p className={GlobalStyle.textSmallItalic}>
          We’ve compiled answers to common questions to help you understand our
          services better.
        </p>
        <div className="w-full max-w-4xl">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b mb-4">
              <button
                onClick={() => toggleFaq(index)}
                className={`w-full text-left p-4 text-lg font-medium focus:outline-none flex justify-between items-center ${
                  activeIndex === index ? "text-primary" : "text-gray-500"
                }`}
              >
                <span>{faq.question}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                    activeIndex === index ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  activeIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <p className="p-4 text-gray-600">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionSix;
