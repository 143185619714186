import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cards from "react-credit-cards-2";
import { GoArrowSwitch } from "react-icons/go";
import CardTransactions from "../../components/transaction/CardTransactions";
import api from "../../api/api";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { fetchCards } from "../../features/cardSlice";
import PinModal from "../../components/pin/PinModal";

const ViewCard = () => {
  const { id } = useParams();
  const cards = useSelector((state) => state.card.items);
  const card = cards.find((card) => card.id === parseInt(id));
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleFocus = () => {
    setFocus((prevFocus) => (prevFocus === "cvc" ? "" : "cvc")); // Toggle focus between "cvc" and ""
  };

  const deleteCardWithPin = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/destroy-card/${id}`, { pin });
      toast.success(res.data.message);
      dispatch(fetchCards());
      navigate(-1);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
      setShowPinModal(false);
      setPin("");
    }
  };

  const deleteCard = () => {
    setShowPinModal(true);
  };

  if (!card) {
    return (
      <div className={GlobalStyle.containerAdmin}>
        <div className={GlobalStyle.adminWrap}>
          <h2 className={GlobalStyle.header}>Card details</h2>
          <p className={GlobalStyle.textCenter}>Card not found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Card details</h2>
        <div className="w-full flex gap-5 max-lg:flex-col mt-5">
          <div className={GlobalStyle.sectionContainer}>
            <h3 className={GlobalStyle.textPrimaryBold}>Details</h3>
            <Cards
              number={card.card_number}
              expiry={card.expiration_date}
              cvc={card.cvv}
              name={card.card_holder_name}
              focused={focus}
            />
            <button
              onClick={toggleFocus}
              className="flex flex-col items-center text-gray-400 mt-3"
            >
              <span className="text-sm">
                {focus === "cvc" ? "Show front" : "Show back"}
              </span>
              <GoArrowSwitch />
            </button>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Card type:</span>
              <span className="capitalize">{card.card_type}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Name on card:</span>
              <span className="capitalize">{card.card_holder_name}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Created on:</span>
              <span className="capitalize">
                {new Date(card.created_at).toLocaleString()}
              </span>
            </div>
            <button onClick={deleteCard} className={GlobalStyle.buttonFull}>
              {loading ? <LoadingSpinner /> : "Destroy card"}
            </button>
          </div>
          <CardTransactions cardId={parseInt(id)} />
        </div>
      </div>
      {showPinModal && (
        <PinModal
          pin={pin}
          setPin={setPin}
          onSubmit={deleteCardWithPin}
          onCancel={() => {
            setShowPinModal(false);
            setPin("");
          }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ViewCard;
