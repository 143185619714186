import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import holder from "../../../images/holder.jpeg";
import { Link } from "react-router-dom";

const testimonies = [
  {
    name: "Sarah M.",
    testimony:
      "Clyveris Bank made opening my savings account effortless. Their support team is incredible!",
  },
  {
    name: "James L.",
    testimony: "Thanks to Clyveris Bank, I got my first home loan with ease!",
  },
  {
    name: "Emily R.",
    testimony:
      "The online banking platform is user-friendly and secure. Highly recommend!",
  },
];

const AboutUs = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>About Us</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>About Clyveris Bank</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Clyveris Bank is redefining personal banking with tailored solutions
            and exceptional customer care.
          </span>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Our Journey</h2>
              <p className={GlobalStyle.text}>
                Clyveris Bank was founded with a simple goal: to provide
                reliable, customer-focused banking services tailored to meet
                your unique financial needs. With years of experience and
                innovation, we've grown into a trusted partner for individuals
                and families across America.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Our Mission and Core Values
              </h2>
              <h3 className={GlobalStyle.textPrimaryBold}>Mission</h3>
              <p className={GlobalStyle.text}>
                To empower individuals and families by providing accessible,
                secure, and innovative financial solutions.
              </p>
              <h3 className={GlobalStyle.textPrimaryBold}>Core Values</h3>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>
                  Integrity: We operate with transparency and uphold the highest
                  ethical standards.
                </li>
                <li className={GlobalStyle.text}>
                  Innovation: Constantly evolving to meet the modern banking
                  needs of our customers.
                </li>
                <li className={GlobalStyle.text}>
                  Customer Focus: Your satisfaction is our priority.
                </li>
                <li className={GlobalStyle.text}>
                  Inclusivity: Providing banking services for everyone,
                  regardless of background.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Why Clyveris Bank?</h2>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>
                  Personalized Banking: Tailored solutions for your financial
                  goals.
                </li>
                <li className={GlobalStyle.text}>
                  Secure Transactions: Advanced security systems to keep your
                  funds safe.
                </li>
                <li className={GlobalStyle.text}>
                  24/7 Customer Support: We're here whenever you need us.
                </li>
                <li className={GlobalStyle.text}>
                  Innovative Features: Cutting-edge technology to make banking
                  seamless.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                What Our Customers Are Saying
              </h2>
              <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                {testimonies.map((item) => (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="w-full flex gap-4 p-5 shadow rounded-xl bg-gray-50 mt-5"
                  >
                    <img
                      src={holder}
                      alt=""
                      className="w-14 h-14 border rounded-full"
                    />
                    <div className="w-full flex flex-col justify-between gap-3">
                      <span className="text-gray-400 italic text-sm">
                        "{item.testimony}"
                      </span>
                      <span className="w-full text-right text-primary text-sm">
                        - {item.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Giving Back to the Community
              </h2>
              <p className={GlobalStyle.text}>
                Clyveris Bank is proud to contribute to local communities
                through scholarships, sponsorships, and volunteer initiatives.
                We believe in building stronger, more connected communities by
                giving back.
              </p>
            </div>
          </div>
          <p className={GlobalStyle.textSmallItalic}>
            Join the Clyveris Bank family and experience the difference in
            personal banking.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
