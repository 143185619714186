import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";

const DashboardCS = () => {
  const transactions = useSelector((state) => state.transaction.items);

  const calculateCreditScore = () => {
    // Example formula for calculating credit score
    const totalAmount = transactions.reduce((sum, transaction) => {
      return sum + parseFloat(transaction.amount || 0);
    }, 0);

    // Normalize the score to a range (e.g., 300 - 850)
    const maxScore = 850;
    const minScore = 300;
    const creditScore = Math.min(
      maxScore,
      Math.max(minScore, Math.floor(totalAmount / 10))
    );

    // Determine the credit score message
    let message = "";
    let color = "";

    if (creditScore >= 750) {
      message = "Excellent";
      color = "text-green-500";
    } else if (creditScore >= 700) {
      message = "Good";
      color = "text-blue-500";
    } else if (creditScore >= 650) {
      message = "Fair";
      color = "text-yellow-500";
    } else if (creditScore >= 600) {
      message = "Poor";
      color = "text-orange-500";
    } else {
      message = "Very Poor";
      color = "text-red-500";
    }

    return { creditScore, message, color };
  };

  const { creditScore, message, color } = calculateCreditScore();

  return (
    <div className={GlobalStyle.sectionContainer2}>
      <div className="w-full">
        <h3 className={GlobalStyle.textPrimaryBold}>Credit score</h3>
      </div>
      <div className="w-full flex justify-between items-center">
        <span className="text-5xl font-extralight text-gray-400">
          {creditScore}
        </span>
        <span className={`text-sm font-bold ${color}`}>{message}</span>
      </div>
    </div>
  );
};

export default DashboardCS;
