import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import { RxArrowBottomRight, RxArrowTopRight } from "react-icons/rx";
import { formatDollar } from "../../global/formatDollar";
import TransactionModal from "../../components/modal/TransactionModal";

const TransactionPage = () => {
  const transactions = useSelector((state) => state.transaction.items);
  const [filter, setFilter] = useState("default");
  const [search, setSearch] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const formatTransactionType = (type) => {
    return type === "card_payment" ? "Card Payment" : "Wire Transfer";
  };

  const handleSortChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const filteredTransactions = transactions
    .filter((item) => {
      if (filter === "cards" && !item.card_id) return false;
      if (filter === "account" && !item.account_id) return false;
      return true;
    })
    .filter((item) =>
      item.transaction_reference.toLowerCase().includes(search.trim())
    )
    .slice()
    .reverse();

  const groupedTransactions = filteredTransactions.reduce((groups, item) => {
    const date = new Date(item.created_at);
    const monthYear = `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
    if (!groups[monthYear]) groups[monthYear] = [];
    groups[monthYear].push(item);
    return groups;
  }, {});

  const showTransaction = (item) => {
    setTransaction(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTransaction(null);
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Transaction overview</h2>
        <div className={GlobalStyle.transactionContainer}>
          <div className={GlobalStyle.transactionContainerWrap}>
            <div className="w-full flex max-md:flex-col md:items-center max-md:gap-2 justify-between">
              <h3 className={GlobalStyle.textPrimaryBold}>
                Latest transactions
              </h3>
              <div className="flex gap-1 items-center">
                <span className="text-xs text-primary">Sort by:</span>
                <select
                  className={GlobalStyle.inputSmall}
                  value={filter}
                  onChange={handleSortChange}
                >
                  <option value="default">Default</option>
                  <option value="cards">Cards</option>
                  <option value="account">Accounts</option>
                </select>
              </div>
            </div>
            <input
              type="text"
              className={GlobalStyle.input}
              placeholder="Search by reference"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          <div className="overflow-y-auto max-h-[470px] flex flex-col gap-3 mt-3">
            {Object.entries(groupedTransactions).length === 0 ? (
              <div className="w-full text-center">
                <div className={GlobalStyle.textSmallPrimary}>
                  {search
                    ? "No transactions match your search."
                    : "No transactions available."}
                </div>
              </div>
            ) : (
              Object.entries(groupedTransactions).map(([monthYear, items]) => (
                <div key={monthYear}>
                  <h4 className={GlobalStyle.textSmall}>{monthYear}</h4>
                  <div className="w-full flex flex-col gap-1">
                    {items.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => showTransaction(item)}
                        className="w-full py-1 px-3 rounded-3xl bg-primary/10 grid grid-cols-3 text-xs md:text-sm"
                      >
                        <div className="w-full h-full flex items-center gap-1 text-primary">
                          {item.direction === "outgoing" ? (
                            <RxArrowTopRight className="text-xl text-red-500" />
                          ) : (
                            <RxArrowBottomRight className="text-xl text-green-500" />
                          )}
                          <span>
                            {formatTransactionType(item.transaction_type)}
                          </span>
                        </div>
                        <div className={GlobalStyle.textTransaction}>
                          {formatDollar(item.amount)}
                        </div>
                        <div className="w-full flex items-center justify-end">
                          <div className={GlobalStyle.status}>
                            {item.status}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {showModal && transaction && (
          <TransactionModal
            transaction={transaction}
            closeModal={closeModal}
            formatTransactionType={formatTransactionType}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionPage;
