import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsPiggyBank } from "react-icons/bs";
import { CiMoneyCheck1 } from "react-icons/ci";
import { FaRegCopy, FaCheck } from "react-icons/fa"; // Icons for copying
import { formatDollar } from "../../global/formatDollar";
import AccountTransactions from "../../components/transaction/AccountTransactions";

const ViewAccount = () => {
  const { number } = useParams();
  const user = useSelector((state) => state.user.profile);
  const accounts = useSelector((state) => state.account.items);
  const account = accounts.find((item) => item.account_number === number);

  const [copiedField, setCopiedField] = useState(null);

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000); // Reset after 2 seconds
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Account details</h2>
        <div className="w-full mt-5 flex gap-5 max-lg:flex-col">
          <div className={GlobalStyle.sectionContainer}>
            <span className="flex items-center gap-2 capitalize">
              <h3 className={GlobalStyle.textPrimaryBold}>
                {account.account_type} account
              </h3>
              {account.account_type === "savings" ? (
                <BsPiggyBank className="text-xl text-primary" />
              ) : (
                <CiMoneyCheck1 className="text-xl text-primary" />
              )}
            </span>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Account number:</span>
              <button
                onClick={() =>
                  copyToClipboard(account.account_number, "account_number")
                }
                className="capitalize flex items-center gap-2"
              >
                <span className="text-primary">
                  {copiedField === "account_number" ? (
                    <FaCheck className="text-green-500" />
                  ) : (
                    <FaRegCopy />
                  )}
                </span>
                {account.account_number}
              </button>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Routing number:</span>
              <button
                onClick={() =>
                  copyToClipboard(account.routing_number, "routing_number")
                }
                className="capitalize flex items-center gap-2"
              >
                <span className="text-primary">
                  {copiedField === "routing_number" ? (
                    <FaCheck className="text-green-500" />
                  ) : (
                    <FaRegCopy />
                  )}
                </span>
                {account.routing_number}
              </button>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Bank:</span>
              <span className="capitalize">CrestWell Bank</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Name on account:</span>
              <span className="capitalize">
                {user.firstname} {user.lastname}
              </span>
            </div>
            <span className={GlobalStyle.moneyText}>
              {formatDollar(account.balance)}
            </span>
            <Link
              to={`/online-banking/transfer`}
              className={GlobalStyle.buttonFull}
            >
              Send money
            </Link>
          </div>
          <AccountTransactions accountId={account.id} />
        </div>
      </div>
    </div>
  );
};

export default ViewAccount;
