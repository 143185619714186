import React, { useState, useEffect } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import Cards from "react-credit-cards-2";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CiCreditCard2 } from "react-icons/ci";
import { GoArrowSwitch } from "react-icons/go";

const CardsPage = () => {
  const cards = useSelector((state) => state.card.items);
  const [cardStates, setCardStates] = useState({});

  useEffect(() => {
    // Initialize visibility and focus state for each card
    const initialCardStates = cards.reduce((acc, card) => {
      acc[card.id] = { hidden: true, focus: "" }; // All cards hidden and no focus initially
      return acc;
    }, {});
    setCardStates(initialCardStates);
  }, [cards]);

  const toggleCardVisibility = (cardId) => {
    setCardStates((prev) => ({
      ...prev,
      [cardId]: {
        ...prev[cardId],
        hidden: !prev[cardId].hidden, // Toggle hidden state
      },
    }));
  };

  // Set focus for a specific card (e.g., show "cvc" or reset focus)
  const setFocus = (cardId, focusField) => {
    setCardStates((prev) => ({
      ...prev,
      [cardId]: {
        ...prev[cardId],
        focus: focusField, // Set the focus field
      },
    }));
  };

  // Mask card number to show only first 4 and last 4 digits
  const maskCardNumber = (cardNumber) => {
    return `${cardNumber.slice(0, 4)} •••• •••• ${cardNumber.slice(-4)}`;
  };

  if (cards.length === 0) {
    return (
      <div className={GlobalStyle.containerAdmin}>
        <div className={GlobalStyle.adminWrap}>
          <h2 className={GlobalStyle.header}>Cards Overview</h2>
          <p className={GlobalStyle.textCenter}>
            You don't have a card with us.
          </p>
          <div className="w-full flex justify-center">
            <Link
              to={"/online-banking/cards/get-new-card"}
              className={GlobalStyle.buttonSmall}
            >
              Get a card
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Cards Overview</h2>
        <div className="w-full flex justify-end">
          <Link
            to={"/online-banking/cards/get-new-card"}
            className={GlobalStyle.buttonSmall}
          >
            Get a card
          </Link>
        </div>
        <div className="w-full flex flex-col mt-5 gap-4">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {cards.map((card) => (
              <div key={card.id} className="w-full">
                <Cards
                  number={
                    cardStates[card.id]?.hidden
                      ? maskCardNumber(card.card_number)
                      : card.card_number
                  }
                  expiry={
                    cardStates[card.id]?.hidden ? "••/••" : card.expiration_date
                  }
                  cvc={cardStates[card.id]?.hidden ? "•••" : card.cvv}
                  name={card.card_holder_name}
                  focused={cardStates[card.id]?.focus} // Use the focus state
                />
                <div className="flex justify-center items-center mt-3 gap-5">
                  <button
                    onClick={() => toggleCardVisibility(card.id)}
                    className="flex flex-col items-center text-gray-400"
                  >
                    <span className="text-sm">
                      {cardStates[card.id]?.hidden
                        ? "Show details"
                        : "Hide details"}
                    </span>
                    {cardStates[card.id]?.hidden ? (
                      <FaRegEye />
                    ) : (
                      <FaRegEyeSlash />
                    )}
                  </button>
                  <button
                    onClick={() =>
                      setFocus(
                        card.id,
                        cardStates[card.id]?.focus === "cvc" ? "" : "cvc"
                      )
                    }
                    className="flex flex-col items-center text-gray-400"
                  >
                    <span className="text-sm">
                      {cardStates[card.id]?.focus === "cvc"
                        ? "Show front"
                        : "Show back"}
                    </span>
                    <GoArrowSwitch />
                  </button>
                  <Link
                    to={`/online-banking/cards/${card.id}`}
                    className="flex flex-col items-center text-gray-400"
                  >
                    <span className="text-sm">View card</span>
                    <CiCreditCard2 />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsPage;
