const GlobalStyle = {
  container: "w-full flex flex-col",
  containerCenter:
    "w-full h-screen bg-default bg-no-repeat bg-cover max-md:bg-right",
  containerAdmin:
    "w-full h-screen bg-default bg-no-repeat bg-cover max-md:bg-right pt-20 text-secondary pl-64 max-lg:pl-0",
  centerWrap:
    "w-full h-full flex flex-col items-center justify-center pt-20 transition-all ease-in-out duration-1000",
  adminWrap:
    "w-full h-full flex flex-col gap-1 p-5 lg:p-10 overflow-scroll dark:bg-slate-900/95 transition-all ease-in-out duration-1000",
  adminWrap2:
    "w-full h-full flex flex-col gap-1 p-5 lg:p-10 overflow-scroll dark:bg-slate-900/95 transition-all ease-in-out duration-1000",
  buttonSmall:
    "w-36 h-min bg-primary text-white text-sm py-3 rounded-3xl hover:bg-primary/50 transition-all ease-in-out duration-500 text-center cursor-pointer",
  buttonFull:
    "w-full h-min bg-primary text-white text-sm py-3 rounded-3xl hover:bg-primary/50 transition-all ease-in-out duration-500 text-center cursor-pointer",
  buttonFullAlternate:
    "w-full h-min bg-transparent text-primary text-sm py-3 rounded-3xl hover:bg-primary/10 transition-all ease-in-out duration-500 text-center cursor-pointer border border-primary hover:border-transparent hover:scale-95",
  form: "w-11/12 md:w-1/2 lg:w-1/3 p-5 lg:p-10 lg:bg-white dark:lg:bg-slate-900 flex flex-col items-center gap-4 lg:shadow rounded-3xl",
  form2:
    "w-11/12 md:w-1/2 lg:w-1/3 p-5 lg:p-10 lg:bg-white flex flex-col items-center gap-4 lg:shadow rounded-3xl",
  header: "text-xl lg:text-3xl text-primary font-medium capitalize",
  headerSub: "lg:text-xl text-primary font-medium capitalize",
  input:
    "w-full text-primary bg-white dark:bg-slate-900/40 dark:border-primary outline-none py-2 px-5 text-sm border rounded-3xl appearance-none transition-all ease-in-out duration-1000",
  input3:
    "w-full text-primary bg-white dark:border-primary outline-none py-2 px-5 text-sm border rounded-3xl appearance-none transition-all ease-in-out duration-1000",
  input2:
    "w-full text-primary bg-white dark:bg-slate-900/40 dark:border-primary outline-none py-2 px-5 text-sm border rounded-3xl appearance-none transition-all ease-in-out duration-1000 capitalize",
  inputSmall:
    "w-32 text-primary bg-white dark:bg-slate-900/40 dark:border-primary outline-none py-1 px-3 text-xs text-primary border rounded-3xl appearance-none transition-all ease-in-out duration-1000 capitalize",
  text: "text-gray-500 text-sm",
  textSmall: "text-gray-500 text-xs",
  textSmallItalic: "text-gray-600 text-xs italic text-center",
  textCenter2: "text-gray-500 text-sm text-center",
  textSmallPrimary: "text-gray-500 text-xs text-primary",
  textPrimaryCapital: "text-primary font-medium capitalize text-lg",
  textPrimaryBold: "text-primary font-medium",
  textCenter: "text-gray-500 w-full text-center py-5",
  textTransaction:
    "w-full h-full flex items-center justify-center font-mono text-gray-500",
  linkSmall:
    "text-gray-500 text-xs hover:text-primary transition-all ease-in-out duration-500",
  moneyText: "font-medium font-mono text-lg text-gray-600",
  modal:
    "fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-40",
  transactionModalContainer:
    "bg-white dark:bg-slate-900 p-8 rounded-lg w-96 flex flex-col gap-4 items-center transition-all ease-in-out duration-1000",
  transactionModalColumn:
    "w-full flex justify-between items-center text-xs text-gray-500",
  status:
    "w-16 bg-primary text-xs flex justify-center items-center py-1 rounded-3xl text-white capitalize",
  sectionContainer:
    "w-full flex flex-col items-center gap-2 bg-white p-10 rounded-3xl shadow h-fit dark:bg-slate-900 transition-all ease-in-out duration-1000",
  sectionContainer2:
    "w-full flex flex-col items-center gap-2 bg-white p-10 rounded-3xl shadow dark:bg-slate-900 transition-all ease-in-out duration-1000",
  sectionContainer3:
    "w-full md:w-2/3 lg:w-1/2 flex flex-col items-center gap-2 bg-white p-10 rounded-3xl shadow dark:bg-slate-900 transition-all ease-in-out duration-1000",
  transactionContainer:
    "w-full mt-5 bg-white dark:bg-slate-900 p-5 rounded-3xl shadow transition-all ease-in-out duration-1000",
  transactionContainerWrap: "top-0 z-10 gap-2 flex flex-col w-full",
  accountContainer:
    "w-full p-5 rounded-3xl bg-white shadow flex flex-col gap-2 dark:bg-slate-900 transition-all ease-in-out duration-1000",
  pinContainer:
    "bg-white dark:bg-slate-900 p-8 rounded-lg w-96 flex flex-col gap-4 items-center transition-all ease-in-out duration-1000",
  info: "w-full bg-primary/10 rounded-3xl py-2 px-5 text-xs text-primary",
  breadContainter: "bg-default3 bg-center bg-cover",
  breadWrap:
    "bg-gradient-to-r from-black to-black/50 py-20 px-10 flex items-center gap-2 text-white font-light",
};

export default GlobalStyle;
