import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import savings from "../../images/homeImages/savings.png";
import checkings from "../../images/homeImages/checkings.png";
import { Link } from "react-router-dom";

const accountTypes = [
  {
    accountType: "Checking Account",
    image: checkings,
    tagline: "Your everyday banking made simple.",
    features: [
      "No monthly maintenance fees.",
      "Unlimited transactions with no minimum balance requirement.",
      "Access to online and mobile banking for quick and easy payments.",
      "Free debit card issued upon account creation.",
    ],
    idealFor: [
      "Day-to-day purchases",
      "Bill payments",
      "Seamless money transfers",
    ],
    link: "/accounts/checking",
  },
  {
    accountType: "Savings Account",
    image: savings,
    tagline: "Save smartly, grow steadily.",
    features: [
      "Competitive interest rates to grow your money.",
      "No withdrawal penalties for your first six transactions each month.",
      "Automatic savings tools to help you reach your financial goals faster.",
      "Secure and accessible 24/7 via online and mobile banking.",
    ],
    idealFor: [
      "Building your financial future",
      "Emergency funds",
      "Long-term savings",
    ],
    link: "/accounts/savings",
  },
];

const SectionFour = () => {
  return (
    <div className="w-full min-h-screen bg-white bg-cover bg-no-repeat pt-20">
      <div className="w-full h-full flex flex-col p-10 items-center gap-10">
        <h2 className={GlobalStyle.header}>
          Choose the Perfect Account for Your Needs.
        </h2>
        <p className={GlobalStyle.textSmallItalic}>
          At Clyveris Bank, we offer account options designed to suit your
          financial lifestyle.
        </p>
        <div className="w-full lg:w-2/3 h-full grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center">
          {accountTypes.map((item) => (
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="w-full flex flex-col shadow-lg relative items-center group hover:shadow-2xl transition-all ease-in-out duration-1000"
            >
              <img src={item.image} alt="alt" className="w-full h-auto" />
              <div className="w-full p-5 group-hover:bg-gray-100 transition-all ease-in-out duration-1000">
                <div className={GlobalStyle.textSmallItalic}>
                  {item.tagline}
                </div>
                <div className="bg-primary text-sm capitalize absolute group-hover:scale-110 group-hover:w-1/2 group-hover:bg-gradient-to-r group-hover:from-primary group-hover:to-black/50 transition-all ease-in-out duration-1000 top-1/3  md:top-1/2 left-5 py-2 px-4 w-full text-white font-light shadow">
                  {item.accountType}
                </div>
                <h3 className={GlobalStyle.textPrimaryBold}>Features</h3>
                <ul className="list-disc list-inside lg:line-clamp-6">
                  {item.features.map((item) => (
                    <li className={GlobalStyle.textSmall} key={item}>
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="w-full text-center">
                  <Link to={item.link} className={GlobalStyle.linkSmall}>View more</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
