import React from "react";
import GlobalStyle from "../../global/GlobalStyle";

const BasicInfo = ({ user }) => {
  return (
    <div className={GlobalStyle.sectionContainer}>
      <div className="w-full">
        <h3 className={GlobalStyle.textPrimaryBold}>User Information</h3>
      </div>
      <div className="w-full flex flex-col gap-2 mt-2">
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Name:</span>
          <span className="capitalize">
            {user.firstname} {user.lastname}
          </span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Email:</span>
          <span className="">{user.email}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Bank ID:</span>
          <span className="">{user.banking_id}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Member since:</span>
          <span className="">
            {new Date(user.created_at).toLocaleDateString()}
          </span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Suspension status:</span>
          <span className="">
            {user.suspended === 0 ? "Not suspended" : "Suspended"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
