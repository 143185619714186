import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo.png";
import api from "../../api/api";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import LoadingSpinner from "../../components/loader/LoadingSpinner";

const Login = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const user = { identifier, password };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/login", user);
      localStorage.setItem("access_token", res.data.access_token);
      toast.success("Log in successful");
      navigate("/online-banking/dashboard");
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={GlobalStyle.containerCenter}>
      <div className={GlobalStyle.centerWrap}>
        <form className={GlobalStyle.form2} onSubmit={handleSubmit}>
          <div className="flex w-full justify-between items-center">
            <h2 className={GlobalStyle.header}>Welcome Back</h2>
            <img src={logo} alt="logo" className="w-16 h-16" />
          </div>
          <input
            type="text"
            className={GlobalStyle.input3}
            placeholder="Email or Banking ID"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            className={GlobalStyle.input3}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />{" "}
          <div className="w-full flex justify-end text-gray-500">
            {hidden ? (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <FaRegEye />
              </div>
            ) : (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <FaRegEyeSlash />
              </div>
            )}
          </div>
          <button className={GlobalStyle.buttonFull}>
            {loading ? <LoadingSpinner /> : "Log in"}
          </button>
          <Link
            to={"/reset-account-password"}
            className={GlobalStyle.linkSmall}
          >
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
