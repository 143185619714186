import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../features/userSlice";
import accountSlice from "../features/accountSlice";
import cardSlice from "../features/cardSlice";
import transactionSlice from "../features/transactionSlice";
import statsSlice from "../features/statsSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    account: accountSlice,
    card: cardSlice,
    transaction: transactionSlice,
    stats: statsSlice,
  },
});

export default store;
