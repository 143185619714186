import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import api from "../../api/api";
import toast from "react-hot-toast";

const SettingsAddress = ({ user }) => {
  const [address, setAddress] = useState("");
  const [zip_code, setZip_code] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setAddress(user.address);
      setZip_code(user.zip_code);
    }
  }, [user]);

  const updateAddress = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/update-address", { address, zip_code });
      toast.success(res.data.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={GlobalStyle.sectionContainer2}>
      <form className="w-full flex flex-col gap-2" onSubmit={updateAddress}>
        <h3 className={GlobalStyle.textPrimaryBold}>Address</h3>
        <input
          className={GlobalStyle.input}
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          className={GlobalStyle.input}
          placeholder="Zip Code"
          value={zip_code}
          onChange={(e) => setZip_code(e.target.value)}
        />
          <button className={GlobalStyle.buttonFull}>
            {loading ? "Updating..." : "Save changes"}
          </button>
      </form>
    </div>
  );
};

export default SettingsAddress;
