import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { formatDollar } from "../../global/formatDollar";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import api from "../../api/api";
import Cards from "react-credit-cards-2";
import { fetchAccounts } from "../../features/accountSlice";
import { fetchCards } from "../../features/cardSlice";
import { useNavigate } from "react-router-dom";
import { fetchTransactions } from "../../features/transactionSlice";
import PinModal from "../../components/pin/PinModal";

const GetCard = () => {
  const accounts = useSelector((state) => state.account.items);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState({});
  const [formData, setFormData] = useState({
    account_id: "",
    card_type: "debit",
  });
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePinSubmit = async () => {
    setLoading(true);
    try {
      const res = await api.post("/cards", { ...formData, pin });
      toast.success(res.data.message);
      setCard(res.data.card);
      dispatch(fetchAccounts());
      dispatch(fetchCards());
      dispatch(fetchTransactions());

      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.log(error);
    } finally {
      setLoading(false);
      setShowPinModal(false);
      setPin("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.account_id) {
      toast.error("Please select an account.");
      return;
    }
    setShowPinModal(true);
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Get new card</h2>
        <div className="w-full flex justify-center mt-5">
          <div className={GlobalStyle.sectionContainer3}>
            <h2 className={GlobalStyle.textPrimaryBold}>Apply for card</h2>
            <form
              className="w-full flex flex-col gap-4"
              onSubmit={handleSubmit}
            >
              <select
                className={GlobalStyle.input2}
                name="account_id"
                value={formData.account_id}
                onChange={handleInputChange}
              >
                <option value="">For which account?</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.account_type} account({account.account_number}){" "}
                    {formatDollar(account.balance)}
                  </option>
                ))}
              </select>
              <select
                className={GlobalStyle.input2}
                name="card_type"
                value={formData.card_type}
                onChange={handleInputChange}
              >
                <option value="debit">Debit</option>
                <option value="credit">Credit</option>
              </select>
              <div className={GlobalStyle.info}>
                Note that an amount of {formatDollar(5)} will be charged as card
                Issuance Fee.
              </div>
              <button
                type="submit"
                className={GlobalStyle.buttonFull}
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
        {Object.keys(card).length > 0 && (
          <div className="w-full flex justify-center mt-5">
            <Cards
              number={card.card_number}
              expiry={card.expiration_date}
              cvc={card.cvc}
              name={card.card_holder_name}
              preview={true}
            />
          </div>
        )}
        <div className="w-full flex justify-center mt-5">
          <button
            onClick={() => navigate(-1)}
            className={GlobalStyle.linkSmall}
          >
            Back
          </button>
        </div>
      </div>
      {showPinModal && (
        <PinModal
          pin={pin}
          setPin={setPin}
          onSubmit={handlePinSubmit}
          onCancel={() => {
            setShowPinModal(false);
            setPin("");
          }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default GetCard;
