import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { formatDollar } from "../../global/formatDollar";
import { maskSensitiveData } from "../../global/maskSensitiveData";

const TransactionModal = ({ transaction, formatTransactionType, closeModal }) => {
  return (
    <div className={GlobalStyle.modal}>
      <div className={GlobalStyle.transactionModalContainer}>
        <h3 className={GlobalStyle.textPrimaryBold}>Transaction details</h3>
        <span className="text-lg text-gray-500 pt-2">
          {transaction.direction === "outgoing" ? "-" : "+"}{" "}
          {formatDollar(transaction.amount)}
        </span>
        <span className={GlobalStyle.status}>{transaction.status}</span>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Type:</span>
          <span>{formatTransactionType(transaction.transaction_type)}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Reference ID:</span>
          <span>{transaction.transaction_reference}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Date:</span>
          <span>{new Date(transaction.created_at).toLocaleDateString()}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Time:</span>
          <span>{new Date(transaction.created_at).toLocaleTimeString()}</span>
        </div>
        {transaction.transaction_type === "transfer" && (
          <div className={GlobalStyle.transactionModalColumn}>
            <span>From:</span>
            <span>{maskSensitiveData(transaction.senders_account)}</span>
          </div>
        )}
        {transaction.account_id !== null && transaction.card_id === null && (
          <>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Bank:</span>
              <span>{transaction.bank_name}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>To:</span>
              <span>{transaction.recipient_account}</span>
            </div>
            <div className={GlobalStyle.transactionModalColumn}>
              <span>Routing number:</span>
              <span>{transaction.routing_number}</span>
            </div>
          </>
        )}
        {transaction.description !== null && (
          <div className={GlobalStyle.transactionModalColumn}>
            <span>Description:</span>
            <span>{transaction.description}</span>
          </div>
        )}
        <button className={GlobalStyle.buttonFull} onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
};

export default TransactionModal;
