import React, { useState } from "react";
import { RxArrowBottomRight, RxArrowTopRight } from "react-icons/rx";
import { useSelector } from "react-redux";
import GlobalStyle from "../../global/GlobalStyle";
import { formatDollar } from "../../global/formatDollar";
import TransactionModal from "../modal/TransactionModal";

const LatestTransaction = () => {
  const transactions = useSelector((state) => state.transaction.items);
  const [transaction, setTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const showTransaction = (item) => {
    setTransaction(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTransaction(null);
  };

  const formatTransactionType = (type) => {
    return type === "card_payment" ? "Card Payment" : "Wire Transfer";
  };


  // Function to filter transactions made today
  const filterTransactionsToday = () => {
    const today = new Date();
    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at);
      return (
        transactionDate.getDate() === today.getDate() &&
        transactionDate.getMonth() === today.getMonth() &&
        transactionDate.getFullYear() === today.getFullYear()
      );
    });
  };

  // Get today's transactions
  const todayTransactions = filterTransactionsToday();

  // Get the last transaction from the array
  const lastTransaction = transactions[transactions.length - 1];

  return (
    <div className={GlobalStyle.sectionContainer2}>
      <div className="w-full">
        <h3 className={GlobalStyle.textPrimaryBold}>Last transaction</h3>
      </div>
      <div className="w-full">
        {/* Display number of transactions done today */}
        <span className={GlobalStyle.textSmall}>
          {todayTransactions.length} transactions today
        </span>
      </div>
      {lastTransaction && (
        <button
          key={lastTransaction.id}
          onClick={() => showTransaction(lastTransaction)}
          className="w-full py-1 px-3 rounded-3xl bg-primary/10 grid grid-cols-3 text-xs md:text-sm"
        >
          <div className="w-full h-full flex items-center gap-1 text-primary">
            {lastTransaction.direction === "outgoing" ? (
              <RxArrowTopRight className="text-xl text-red-500" />
            ) : (
              <RxArrowBottomRight className="text-xl text-green-500" />
            )}
            <span>
              {formatTransactionType(lastTransaction.transaction_type)}
            </span>
          </div>
          <div className={GlobalStyle.textTransaction}>
            {formatDollar(lastTransaction.amount)}
          </div>
          <div className="w-full h-full flex items-center justify-end">
            <span className={GlobalStyle.status}>{lastTransaction.status}</span>
          </div>
        </button>
      )}

      {showModal && transaction && (
        <TransactionModal
          transaction={transaction}
          closeModal={closeModal}
          formatTransactionType={formatTransactionType}
        />
      )}
    </div>
  );
};

export default LatestTransaction;
