import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Terms and Conditions</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Terms and Conditions</h1>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <span className={GlobalStyle.text}>
              Welcome to Clyveris Bank. By accessing or using our website,
              mobile applications, and services, you agree to comply with these
              Terms and Conditions. Please read them carefully before engaging
              with our platform.
            </span>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Acceptance of Terms</h2>
              <p className={GlobalStyle.text}>
                By using Clyveris Bank’s services, you confirm that you have
                read, understood, and agreed to these Terms and Conditions. If
                you do not agree, you must not use our services.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Eligibility</h2>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  You must be at least 18 years old or the age of majority in
                  your jurisdiction to use our services.
                </li>
                <li className={GlobalStyle.text}>
                  By registering for an account, you represent that all
                  information you provide is accurate and truthful.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Banking Services</h2>
              <p className={GlobalStyle.text}>
                Clyveris Bank provides personal banking services, including:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Checking and savings accounts
                </li>
                <li className={GlobalStyle.text}>
                  Debit and credit card services
                </li>
                <li className={GlobalStyle.text}>
                  Online banking and fund transfers
                </li>
                <li className={GlobalStyle.text}>
                  Loan offerings (subject to eligibility)
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                These services are subject to applicable laws, and their use may
                require verification of your identity.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Account Holder Responsibilities
              </h2>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Keep your account information, banking ID, and passwords
                  confidential.
                </li>
                <li className={GlobalStyle.text}>
                  Notify us immediately if you suspect unauthorized access to
                  your account.
                </li>
                <li className={GlobalStyle.text}>
                  Ensure sufficient funds are available for transactions to
                  avoid overdraft fees.
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                Failure to comply may result in suspension or termination of
                your account.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Transactions and Payments
              </h2>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  All transactions must comply with our policies and applicable
                  laws.
                </li>
                <li className={GlobalStyle.text}>
                  Clyveris Bank is not liable for errors caused by incorrect
                  information provided by you (e.g., account numbers or
                  amounts).
                </li>
                <li className={GlobalStyle.text}>
                  Card payments, transfers, and other transactions are subject
                  to verification and approval.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Fees and Charges</h2>
              <p className={GlobalStyle.text}>
                Fees for banking services, including account maintenance, card
                replacement, and overdrafts, are outlined in our Fee Schedule.
                Clyveris Bank reserves the right to update fees with prior
                notice.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Prohibited Activities</h2>
              <p className={GlobalStyle.text}>You agree not to:</p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Use our services for illegal purposes.
                </li>
                <li className={GlobalStyle.text}>
                  Provide false or misleading information.
                </li>
                <li className={GlobalStyle.text}>
                  Attempt to breach the security of our systems.
                </li>
                <li className={GlobalStyle.text}>
                  Engage in fraudulent or abusive behavior.
                </li>
              </ul>
              <p className={GlobalStyle.text}>
                Violations may result in legal action and account termination.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Intellectual Property</h2>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Clyveris Bank owns all rights to the content, design, and
                  trademarks on our platform.
                </li>
                <li className={GlobalStyle.text}>
                  You may not copy, reproduce, or distribute any material from
                  our website without prior consent.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Privacy Policy</h2>
              <p className={GlobalStyle.text}>
                Your use of our services is subject to our{" "}
                <Link to={`/privacy-policy`} className="text-primary">
                  Privacy Policy
                </Link>{" "}
                , which explains how we collect, use, and protect your
                information.
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Liability Disclaimer</h2>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Clyveris Bank is not liable for losses caused by unauthorized
                  account access due to your failure to safeguard your
                  credentials.
                </li>
                <li className={GlobalStyle.text}>
                  We are not responsible for third-party actions, including
                  merchants or service providers linked to your account.
                </li>
                <li className={GlobalStyle.text}>
                  Service interruptions due to maintenance or unforeseen
                  circumstances are not the bank's liability.
                </li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Termination of Services</h2>
              <p className={GlobalStyle.text}>
                We reserve the right to suspend or terminate your account or
                access to our services at any time, with or without notice, for
                reasons including but not limited to:
              </p>
              <ul className="list-disc list-inside">
                <li className={GlobalStyle.text}>
                  Violations of these Terms and Conditions.
                </li>
                <li className={GlobalStyle.text}>
                  Suspicious or illegal activity.
                </li>
                <li className={GlobalStyle.text}>Non-payment of fees.</li>
              </ul>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Dispute Resolution</h2>
              <p className={GlobalStyle.text}>
                Any disputes arising from your use of Clyveris Bank’s services
                shall be resolved through binding arbitration, subject to the
                laws of [Insert Jurisdiction].
              </p>
            </div>
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                Updates to Terms and Conditions
              </h2>
              <p className={GlobalStyle.text}>
                We may update these Terms and Conditions from time to time. It
                is your responsibility to review them regularly. Continued use
                of our services signifies your acceptance of the updated terms.
              </p>
            </div>
            <p className={GlobalStyle.text}>
              If you have any questions about these Terms and Conditions, please
              contact us at{" "}
              <Link to={`/contact-us`} className="text-primary">
                Contact Us
              </Link>{" "}
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
