import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import { formatDollar } from "../../global/formatDollar";
import PinSetup from "../../components/pin/PinSetup";
import LatestTransaction from "../../components/transaction/LatestTransaction";
import { FaRegEye } from "react-icons/fa";
import DashboardStats from "../../components/dashboardComponents/DashboardStats";
import DashboardInfo from "../../components/dashboardComponents/DashboardInfo";
import DashboardCS from "../../components/dashboardComponents/DashboardCS";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const user = useSelector((state) => state.user.profile);
  const accounts = useSelector((state) => state.account.items);

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const totalBalance = accounts.reduce((total, account) => {
    return total + parseFloat(account.balance);
  }, 0);

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <PinSetup />
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>
          {getGreeting()}, <span className="capitalize">{user.firstname}</span>
        </h2>
        {parseInt(user.suspended) === 1 ? (
          <div className="w-full bg-primary/10 text-primary text-xs px-3 py-1 rounded-3xl mt-2">
            Your account has been suspended. Please contact support.
          </div>
        ) : null}
        <div className="w-full mt-5 flex gap-5 max-lg:flex-col">
          <div className={GlobalStyle.sectionContainer2}>
            <div className="w-full h-full flex justify-between gap-5">
              <div className="w-full flex flex-col h-full">
                <span className={GlobalStyle.textPrimaryBold}>Balance</span>
                <span className={GlobalStyle.textSmall}>
                  {isBalanceVisible ? formatDollar(totalBalance) : "****"}{" "}
                </span>
              </div>
              <div className="w-full flex items-center justify-end min-h-full text-primary text-xl">
                <FaRegEye
                  onClick={toggleBalanceVisibility}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="w-full h-full flex justify-between gap-5">
              <Link
                to={"/online-banking/accounts"}
                className={GlobalStyle.buttonFull}
              >
                Recieve money
              </Link>
              <Link
                to={"/online-banking/transfer"}
                className={GlobalStyle.buttonFull}
              >
                Send money
              </Link>
            </div>
          </div>
          <LatestTransaction />
        </div>
        <div className="w-full mt-5">
          <DashboardStats />
        </div>
        <div className="w-full mt-5 flex max-lg:flex-col gap-5">
          <DashboardInfo />
          <DashboardCS />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
