import React from "react";
import DashboardNavbar from "../components/navbar/DashboardNavbar";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <DashboardNavbar />
      {children}
    </>
  );
};

export default DashboardLayout;
