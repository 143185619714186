import React, { useState } from "react";
import { PiCaretRightThin } from "react-icons/pi";
import GlobalStyle from "../../../global/GlobalStyle";
import checking from "../../../images/homeImages/checkings.png";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "Is there a fee for overdrafts?",
    answer: "Yes, an overdraft fee of $35 may apply.",
  },
  {
    question: "Can I set up direct deposit?",
    answer: "Yes, you can set up direct deposit with your employer.",
  },
];

const CheckingAccount = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <Link to="/accounts">Accounts</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Checking Account</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Checking Accounts</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Manage your finances effortlessly with Clyveris Bank Checking
            Accounts.
          </span>
          <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
            <img
              data-aos="fade-right"
              data-aos-duration="1000"
              src={checking}
              alt=""
              className="w-full shadow-lg"
            />
            <div className="w-full flex flex-col gap-5">
              <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  No monthly maintenance fees.
                </li>
                <li className={GlobalStyle.text}>
                  Seamless online banking and mobile app integration.
                </li>
                <li className={GlobalStyle.text}>
                  Unlimited transactions with no minimum balance requirement.
                </li>
                <li className={GlobalStyle.text}>
                  Direct deposit for paychecks.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>How It Works</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Apply online or visit a branch.
                </li>
                <li className={GlobalStyle.text}>
                  Provide required documents (ID, proof of address).
                </li>
                <li className={GlobalStyle.text}>
                  Start using your account instantly.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>Eligibility</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>Must be 18 years or older.</li>
                <li className={GlobalStyle.text}>
                  Provide valid identification.
                </li>
                <li className={GlobalStyle.text}>
                  Minimum deposit of $50 to open the account.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <div className="w-full text-center my-2">
              <h4 className={GlobalStyle.textPrimaryBold}>FAQ's</h4>
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b mb-4">
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left p-4 text-sm font-medium focus:outline-none flex justify-between items-center ${
                    activeIndex === index ? "text-primary" : "text-gray-500"
                  }`}
                >
                  <span>{faq.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="p-4 text-gray-600 text-xs">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <p className={GlobalStyle.textSmallItalic}>
            Open your Checking Account today and enjoy the convenience of
            banking on your terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckingAccount;
