import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import api from "../../api/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const MakeChanges = ({ user, getUser }) => {
  const [role, setRole] = useState("");
  const [suspended, setSuspended] = useState("");
  const [roleLoading, setRoleLoading] = useState(false);
  const [suspendedLoading, setSuspendedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setRole(user.role);
      setSuspended(user.suspended);
    }
  }, [user]);

  const handleRoleChange = async (e) => {
    e.preventDefault();
    setRoleLoading(true);
    try {
      const res = await api.post(`/user/${user.id}/role`, { role });
      getUser();
      toast.success(res.data.message);
    } catch (error) {
    } finally {
      setRoleLoading(false);
    }
  };

  const handleSuspensionChange = async (e) => {
    e.preventDefault();
    setSuspendedLoading(true);
    try {
      const res = await api.post(`/user/${user.id}/suspend`);
      getUser();
      toast.success(res.data.message);
    } catch (error) {
    } finally {
      setSuspendedLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const res = await api.post(`/delete-user/${user.id}`);
      toast.success(res.data.message);
      navigate(-1);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="w-full flex max-lg:flex-col gap-5">
      <div className={GlobalStyle.sectionContainer2}>
        <form
          className="w-full px-2 flex flex-col gap-2"
          onSubmit={handleRoleChange}
        >
          <h2 className={GlobalStyle.textPrimaryBold}>Change role</h2>
          <select
            className={GlobalStyle.input}
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          <button className={GlobalStyle.buttonFull}>
            {roleLoading ? "Changing..." : "Change role"}
          </button>
        </form>
      </div>
      <div className={GlobalStyle.sectionContainer2}>
        <form
          className="w-full px-2 flex flex-col gap-2"
          onSubmit={handleSuspensionChange}
        >
          <h2 className={GlobalStyle.textPrimaryBold}>
            Change suspension status
          </h2>
          <p className={GlobalStyle.textSmall}>
            <span className="capitalize">
              {user.firstname} {user.lastname}
            </span>{" "}
            is currently {suspended === 0 ? "not suspended" : "suspended"}.
          </p>
          <p className={GlobalStyle.textSmall}>
            Use the button below to toggle suspension status.
          </p>
          <button className={GlobalStyle.buttonFull}>
            {suspendedLoading ? "Changing..." : "Change status"}
          </button>
        </form>
      </div>
      <div className={GlobalStyle.sectionContainer2}>
        <div
          className="w-full px-2 flex flex-col gap-2"
          onSubmit={handleSuspensionChange}
        >
          <h2 className={GlobalStyle.textPrimaryBold}>Delete user</h2>
          <p className={GlobalStyle.textSmall}>
            Delete{" "}
            <span className="capitalize">
              {user.firstname} {user.lastname}
            </span>
            's profile from the database.
          </p>
          <p className={GlobalStyle.textSmall}>
            This action can not be undone.
          </p>
          <button onClick={handleDelete} className={GlobalStyle.buttonFull}>
            {deleteLoading ? "Deleting..." : "Delete user"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakeChanges;
