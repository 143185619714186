import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import { formatDollar } from "../../global/formatDollar";
import { BsPiggyBank } from "react-icons/bs";
import { CiMoneyCheck1 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { maskSensitiveData } from "../../global/maskSensitiveData";
import AllAccountTransaction from "../../components/transaction/AllAccountTransaction";

const AccountsPage = () => {
  const accounts = useSelector((state) => state.account.items);

  if (accounts.length === 0) {
    return (
      <div className={GlobalStyle.containerAdmin}>
        <div className={GlobalStyle.adminWrap}>
          <h2 className={GlobalStyle.header}>Accounts Overview</h2>
          <p className={GlobalStyle.textCenter}>No accounts found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Accounts Overview</h2>
        <div className="w-full flex flex-col mt-5 gap-5">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {accounts.map((account, index) => (
              <div
                className={GlobalStyle.accountContainer}
                key={index}
              >
                <span className="flex items-center gap-2">
                  <h3 className={GlobalStyle.textPrimaryCapital}>
                    {account.account_type} account
                  </h3>
                  {account.account_type === "savings" ? (
                    <BsPiggyBank className="text-xl text-primary" />
                  ) : (
                    <CiMoneyCheck1 className="text-xl text-primary" />
                  )}
                </span>
                <span className={GlobalStyle.textSmall}>
                  Account Number: {maskSensitiveData(account.account_number)}
                </span>
                <span className={GlobalStyle.textSmall}>
                  Routing Number: {maskSensitiveData(account.routing_number)}
                </span>
                <span className={GlobalStyle.textSmall}>
                  Balance:{" "}
                  <span className="font-mono">
                    {formatDollar(account.balance)}
                  </span>
                </span>
                <Link
                  to={`/online-banking/accounts/${account.account_number}`}
                  className={GlobalStyle.buttonFull}
                >
                  View more
                </Link>
              </div>
            ))}
          </div>
          <AllAccountTransaction />
        </div>
      </div>
    </div>
  );
};

export default AccountsPage;
