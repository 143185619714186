export const banks = [
  "Ally Financial",
  "Ameriprise",
  "Ameris Bancorp",
  "American Express",
  "Arvest Bank",
  "Associated Banc-Corp",
  "Atlantic Union Bank",
  "Axos Financial",
  "Banc of California",
  "Bank of America",
  "Bank of Hawaii",
  "BankUnited",
  "Barclays",
  "BCI Financial Group",
  "Beal Bank",
  "BMO USA",
  "BNP Paribas",
  "BOK Financial Corporation",
  "Capital One",
  "Cathay Bank",
  "Charles Schwab Corporation",
  "CIBC Bank USA",
  "Citigroup",
  "Citizens Financial Group",
  "Columbia Bank",
  "Commerce Bancshares",
  "Comerica",
  "Cullen/Frost Bankers, Inc.",
  "Customers Bancorp",
  "Deutsche Bank",
  "Discover Financial",
  "East West Bank",
  "Eastern Bank",
  "EverBank",
  "Fifth Third Bank",
  "First BanCorp",
  "First Citizens BancShares",
  "First Hawaiian Bank",
  "First Horizon National Corporation",
  "First Interstate BancSystem",
  "First National of Nebraska",
  "FirstBank Holding Co",
  "FNB Corporation",
  "Fulton Financial Corporation",
  "Glacier Bancorp",
  "Goldman Sachs",
  "Hancock Whitney",
  "Home BancShares",
  "HSBC Bank USA",
  "HTLF Bank / Heartland Financial",
  "Huntington Bancshares",
  "Independent Bank",
  "Independent Bank Group, Inc.",
  "JPMorgan Chase",
  "John Deere",
  "KeyCorp",
  "M&T Bank",
  "Midland",
  "Mizuho Financial Group",
  "Morgan Stanley",
  "New York Community Bank",
  "Northern Trust",
  "Old National Bank",
  "PNC Financial Services",
  "Pinnacle Bancorp",
  "Pinnacle Financial Partners",
  "Popular, Inc.",
  "Prosperity Bancshares",
  "Provident Bank of New Jersey",
  "Raymond James Financial",
  "RBC Bank",
  "Regions Financial Corporation",
  "Santander Bank",
  "SoFi",
  "South State Bank",
  "State Street Corporation",
  "Stifel",
  "Sumitomo Mitsui Banking Corporation",
  "Synchrony Financial",
  "TD Bank, N.A.",
  "Texas Capital Bank",
  "The Bank of New York Mellon",
  "Truist Financial",
  "Trustmark",
  "UBS",
  "UMB Financial Corporation",
  "United Bank (West Virginia)",
  "United Community Bank",
  "USAA",
  "U.S. Bancorp",
  "Valley Bank",
  "WaFd Bank",
  "Wells Fargo",
  "Webster Bank",
  "Western Alliance Bancorporation",
  "Wintrust Financial",
  "WSFS Bank",
];
