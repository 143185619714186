import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-6 h-6 border-b-2 border-t-2 border-b-primary border-t-white rounded-full animate-spin transition-all ease-in-out"></div>
    </div>
  );
};

export default LoadingSpinner;
