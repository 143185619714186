import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { PiFileMagnifyingGlass, PiHeadsetFill } from "react-icons/pi";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { SlScreenSmartphone } from "react-icons/sl";

const SectionTwo = () => {
  const why = [
    {
      name: "Top-Notch Security",
      description:
        "Your money and data are protected with industry-leading encryption and fraud detection systems. We ensure your peace of mind by keeping your finances secure 24/7.",
      tagline: "Bank with confidence, knowing your money is safe.",
      icon: <IoShieldCheckmarkOutline />,
    },
    {
      name: "Convenience at Your Fingertips",
      description:
        "Manage your accounts anytime, anywhere, with our user-friendly online and mobile banking platforms. From checking balances to making payments, banking has never been this easy.",
      tagline: "Access your funds whenever you need them.",
      icon: <SlScreenSmartphone />,
    },
    {
      name: "Transparent Banking",
      description:
        "No hidden fees, no surprises—just clear and honest banking. Get complete visibility into your transactions and account statements at any time.",
      tagline: "What you see is what you get.",
      icon: <PiFileMagnifyingGlass />,
    },
    {
      name: "Personal Support",
      description:
        "Have a question or need assistance? Our dedicated customer support team is here for you, providing tailored solutions to meet your banking needs.",
      tagline: "We're here to help you every step of the way.",
      icon: <PiHeadsetFill />,
    },
  ];
  return (
    <div className="w-full min-h-screen bg-gray-100 pt-20">
      <div className="w-full h-full flex flex-col p-10 items-center gap-10">
        <h2 className={GlobalStyle.header}>Banking That Works for You.</h2>
        <p className={GlobalStyle.textSmallItalic}>
          Discover why thousands of customers trust Clyveris Bank for their
          personal banking needs.
        </p>
        <div className="w-full md:w-1/2 gap-5 lg:gap-16 grid grid-cols-1 lg:grid-cols-2">
          {why.map((item) => {
            return (
              <div
                key={item.name}
                data-aos="fade-up"
                data-aos-duration="1000"
                className="w-full h-full flex flex-col gap-2 items-center"
              >
                <span className="text-3xl text-primary">{item.icon}</span>
                <h3 className={GlobalStyle.textPrimaryBold}>{item.name}</h3>
                <h3 className={GlobalStyle.textSmallItalic}>
                  "{item.tagline}"
                </h3>
                <p className={GlobalStyle.textCenter2}>{item.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
