import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrimaryLayout from "./layout/PrimaryLayout";
import Home from "./pages/Home";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import useFetchUser from "./global/useFetchUser";
import DashboardLayout from "./layout/DashboardLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "./features/accountSlice";
import AccountsPage from "./pages/dashboard/AccountsPage";
import CardsPage from "./pages/dashboard/CardsPage";
import { fetchCards } from "./features/cardSlice";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import TransferPage from "./pages/dashboard/TransferPage";
import GetCard from "./pages/dashboard/GetCard";
import TransactionPage from "./pages/dashboard/TransactionPage";
import { fetchTransactions } from "./features/transactionSlice";
import ViewCard from "./pages/dashboard/ViewCard";
import ViewAccount from "./pages/dashboard/ViewAccount";
import LoadingSpinner from "./components/loader/LoadingSpinner";
import logo from "../src/logo.png";
import { fetchStats } from "./features/statsSlice";
import AllUsersPage from "./pages/admin/AllUsersPage";
import SingleUser from "./pages/admin/SingleUser";
import Settings from "./pages/dashboard/Settings";
import ResetPassOtp from "./pages/auth/ResetPassOtp";
import SetNewPassword from "./pages/auth/SetNewPassword";
import ValidatePassword from "./pages/dashboard/ValidatePassword";
import AOS from "aos";
import "aos/dist/aos.css";
import AllAccounts from "./pages/info/accounts/AllAccounts";
import CheckingAccount from "./pages/info/accounts/CheckingAccount";
import SavingsAccounts from "./pages/info/accounts/SavingsAccounts";
import AllCards from "./pages/info/cards/AllCards";
import DebitCards from "./pages/info/cards/DebitCards";
import CreditCard from "./pages/info/cards/CreditCard";
import Loans from "./pages/info/loans/Loans";
import HomeLoan from "./pages/info/loans/HomeLoan";
import PersonalLoans from "./pages/info/loans/PersonalLoans";
import EducationalLoan from "./pages/info/loans/EducationalLoan";
import AboutUs from "./pages/info/about/AboutUs";
import CareerPage from "./pages/info/about/CareerPage";
import FaqsPage from "./pages/info/about/FaqsPage";
import PrivacyPolicy from "./pages/info/about/PrivacyPolicy";
import Terms from "./pages/info/about/Terms";

const App = () => {
  useFetchUser();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  const user = useSelector((state) => state.user.profile);
  const accountStatus = useSelector((state) => state.account.status);
  const cardStatus = useSelector((state) => state.card.status);
  const transactionStatus = useSelector((state) => state.transaction.status);
  const statsStatus = useSelector((state) => state.stats.status);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth) {
      const route = location.pathname.includes("/online-banking");
      if (route && !auth) {
        navigate("/");
      }
    }
  }, [auth, user, location.pathname, navigate]);

  useEffect(() => {
    if (auth && user) {
      const isAdminRoute = location.pathname.includes("/online-banking/admin");
      const isAdmin = user.role === "admin";
      if (isAdminRoute && !isAdmin) {
        navigate("/online-banking/dashboard");
      }
    }
  }, [auth, user, location.pathname, navigate]);

  useEffect(() => {
    if (auth && user) {
      if (location.pathname === "/login") {
        navigate("/online-banking/dashboard");
      }
    }
  }, [auth, user, location.pathname, navigate]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accountStatus === "idle" && auth === true) {
      dispatch(fetchAccounts());
    }
  }, [accountStatus, auth, dispatch]);

  useEffect(() => {
    if (cardStatus === "idle" && auth === true) {
      dispatch(fetchCards());
    }
  }, [cardStatus, auth, dispatch]);

  useEffect(() => {
    if (transactionStatus === "idle" && auth === true) {
      dispatch(fetchTransactions());
    }
  }, [transactionStatus, auth, dispatch]);

  useEffect(() => {
    if (statsStatus === "idle" && auth === true) {
      dispatch(fetchStats());
    }
  }, [statsStatus, auth, dispatch]);

  if (accountStatus === "idle" && auth === true) {
    return (
      <div className="w-full bg-white h-screen flex flex-col justify-center items-center gap-8">
        <img src={logo} alt="Logo" className="w-24 h-24" />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrimaryLayout>
            <Home />
          </PrimaryLayout>
        }
      />
      <Route
        path="/accounts"
        element={
          <PrimaryLayout>
            <AllAccounts />
          </PrimaryLayout>
        }
      />
      <Route
        path="/accounts/checking"
        element={
          <PrimaryLayout>
            <CheckingAccount />
          </PrimaryLayout>
        }
      />
      <Route
        path="/accounts/savings"
        element={
          <PrimaryLayout>
            <SavingsAccounts />
          </PrimaryLayout>
        }
      />
      <Route
        path="/cards"
        element={
          <PrimaryLayout>
            <AllCards />
          </PrimaryLayout>
        }
      />
      <Route
        path="/cards/debit"
        element={
          <PrimaryLayout>
            <DebitCards />
          </PrimaryLayout>
        }
      />
      <Route
        path="/cards/credit"
        element={
          <PrimaryLayout>
            <CreditCard />
          </PrimaryLayout>
        }
      />
      <Route
        path="/loans"
        element={
          <PrimaryLayout>
            <Loans />
          </PrimaryLayout>
        }
      />
      <Route
        path="/loans/home-loan"
        element={
          <PrimaryLayout>
            <HomeLoan />
          </PrimaryLayout>
        }
      />
      <Route
        path="/loans/personal-loan"
        element={
          <PrimaryLayout>
            <PersonalLoans />
          </PrimaryLayout>
        }
      />
      <Route
        path="/loans/educational-loan"
        element={
          <PrimaryLayout>
            <EducationalLoan />
          </PrimaryLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <PrimaryLayout>
            <AboutUs />
          </PrimaryLayout>
        }
      />
      <Route
        path="/careers"
        element={
          <PrimaryLayout>
            <CareerPage />
          </PrimaryLayout>
        }
      />
      <Route
        path="/faqs"
        element={
          <PrimaryLayout>
            <FaqsPage />
          </PrimaryLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <PrimaryLayout>
            <PrivacyPolicy />
          </PrimaryLayout>
        }
      />
      <Route
        path="/terms-and-condition"
        element={
          <PrimaryLayout>
            <Terms />
          </PrimaryLayout>
        }
      />
      <Route
        path="/create-new-profile"
        element={
          <PrimaryLayout>
            <Signup />
          </PrimaryLayout>
        }
      />
      <Route
        path="/login"
        element={
          <PrimaryLayout>
            <Login />
          </PrimaryLayout>
        }
      />
      <Route
        path="/reset-account-password"
        element={
          <PrimaryLayout>
            <ResetPassOtp />
          </PrimaryLayout>
        }
      />
      <Route
        path="/reset-account-password/new-password"
        element={
          <PrimaryLayout>
            <SetNewPassword />
          </PrimaryLayout>
        }
      />
      {/* dashboard */}
      <Route
        path="/online-banking/dashboard"
        element={
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/accounts"
        element={
          <DashboardLayout>
            <AccountsPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/accounts/:number"
        element={
          <DashboardLayout>
            <ViewAccount />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/cards"
        element={
          <DashboardLayout>
            <CardsPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/cards/get-new-card"
        element={
          <DashboardLayout>
            <GetCard />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/cards/:id"
        element={
          <DashboardLayout>
            <ViewCard />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/transfer"
        element={
          <DashboardLayout>
            <TransferPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/transactions"
        element={
          <DashboardLayout>
            <TransactionPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/settings"
        element={
          <DashboardLayout>
            <Settings />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/settings/reset-pin"
        element={
          <DashboardLayout>
            <ValidatePassword />
          </DashboardLayout>
        }
      />
      {/* Admin */}
      <Route
        path="/online-banking/admin/users"
        element={
          <DashboardLayout>
            <AllUsersPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/online-banking/admin/users/:id"
        element={
          <DashboardLayout>
            <SingleUser />
          </DashboardLayout>
        }
      />
    </Routes>
  );
};

export default App;
