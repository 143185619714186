import React, { useState } from "react";
import toast from "react-hot-toast";
import api from "../../api/api";
import GlobalStyle from "../../global/GlobalStyle";
import { useNavigate } from "react-router-dom";

const SetPinModal = ({ isOpen, onClose }) => {
  const [pin, setPin] = useState("");
  const navigate = useNavigate();

  const handleKeypadClick = (value) => {
    if (value === "clear") {
      setPin(""); // Clear the PIN
    } else if (value === "delete") {
      setPin((prev) => prev.slice(0, -1)); // Remove the last digit
    } else if (pin.length < 4) {
      setPin((prev) => prev + value.toString()); // Add the clicked digit
    }
  };

  const handleSavePin = async () => {
    if (pin.length !== 4) {
      toast.error("PIN must be 4 digits.");
      return;
    }
    try {
      await api.post("/user/set-pin", { pin }); // Send PIN to the API
      toast.success("PIN set successfully.");
      onClose();
      navigate(-1);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (err) {
      toast.error(err.response?.data?.message || "Error setting PIN.");
    }
  };

  return (
    isOpen && (
      <div className={GlobalStyle.modal}>
        <div className={GlobalStyle.pinContainer}>
          <h2 className={GlobalStyle.header}>Set Your PIN</h2>
          <p className={GlobalStyle.textSmall}>
            Create a 4-Digit PIN for secure access.
          </p>
          <div className="flex justify-center gap-2">
            {Array(4)
              .fill("")
              .map((_, index) => (
                <div
                  key={index}
                  className={`w-4 h-4 rounded-full ${
                    pin.length > index
                      ? "bg-primary"
                      : "bg-gray-300 dark:bg-primary/10"
                  }`}
                ></div>
              ))}
          </div>
          <div className="grid grid-cols-3 gap-5 mt-4">
            {[
              ...[1, 2, 3, 4, 5, 6, 7, 8, 9], // Numbers 1-9
              "clear", // Clear button
              0, // Number 0
              "delete", // Delete button
            ].map((value, index) => (
              <button
                key={index}
                onClick={() => handleKeypadClick(value)}
                className={`p-3 w-12 h-12 rounded-full flex items-center justify-center text-gray-500 text-xs ${
                  value === "clear" || value === "delete"
                    ? "hover:text-primary/80 hover:bg-primary/10"
                    : "hover:text-primary hover:bg-primary/10"
                }`}
              >
                {value === "clear" ? "Clear" : value === "delete" ? "⌫" : value}
              </button>
            ))}
          </div>
          <div className="flex justify-between mt-4 gap-5">
            <button
              onClick={handleSavePin}
              className={GlobalStyle.buttonSmall}
              disabled={pin.length !== 4}
            >
              Save PIN
            </button>
            <button onClick={onClose} className={GlobalStyle.buttonSmall}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SetPinModal;
