import React, { useState } from "react";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import api from "../../api/api";
import toast from "react-hot-toast";
import GlobalStyle from "../../global/GlobalStyle";
import logo from "../../logo.png";
import { useNavigate } from "react-router-dom";

const ResetPassOtp = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/send-otp", { email });
      toast.success(res.data.message);
      sessionStorage.setItem("email", email);
      navigate("/reset-account-password/new-password");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={GlobalStyle.containerCenter}>
      <div className={GlobalStyle.centerWrap}>
        <form className={GlobalStyle.form2} onSubmit={handleSubmit}>
          <div className="flex w-full justify-between items-center">
            <h2 className={GlobalStyle.header}>Get reset OTP</h2>
            <img src={logo} alt="logo" className="w-16 h-16" />
          </div>
          <input
            type="text"
            className={GlobalStyle.input3}
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className={GlobalStyle.buttonFull}>
            {loading ? <LoadingSpinner /> : "Get code"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassOtp;
