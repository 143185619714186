import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import { Link } from "react-router-dom";
import credit from "../../../images/homeImages/ccard.png";
import debit from "../../../images/homeImages/dcard.png";

const AllCards = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to={`/`}>Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Cards</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Our Cards</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Choose from our debit and credit cards to fit your financial needs,
            offering convenience, security, and rewards.
          </span>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <h2 className={GlobalStyle.textPrimaryBold}>Card Types</h2>
            {/* Debit Card */}
            <div className="w-full gap-5 lg:gap-10 grid grid-cols-1 md:grid-cols-2">
              <img
                data-aos="fade-right"
                data-aos-duration="1000"
                src={debit}
                alt="Debit Card"
                className="w-full h-auto shadow-lg"
              />
              <div className="w-full flex flex-col gap-5">
                <h3
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className={GlobalStyle.header}
                >
                  Debit Card
                </h3>
                <p className={GlobalStyle.text}>
                  Enjoy instant access to your money with the security of a
                  Clyveris Bank Debit Card.
                </p>
                <Link to={`/cards/debit`} className={GlobalStyle.buttonSmall}>
                  Learn more
                </Link>
              </div>
            </div>
            {/* Credit Card */}
            <div className="w-full gap-5 lg:gap-10 grid grid-cols-1 md:grid-cols-2">
              <img
                data-aos="fade-left"
                data-aos-duration="1000"
                src={credit}
                alt="Credit Card"
                className="w-full h-auto shadow-lg md:col-start-2"
              />
              <div className="w-full flex flex-col gap-5 md:col-start-1 md:row-start-1">
                <h3
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className={GlobalStyle.header}
                >
                  Credit Card
                </h3>
                <p className={GlobalStyle.text}>
                  Take control of your spending with flexible limits and
                  exclusive rewards using Clyveris Bank Credit Cards.
                </p>
                <Link to={`/cards/credit`} className={GlobalStyle.buttonSmall}>
                  Learn more
                </Link>
              </div>
            </div>
            {/* Why Choose Clyveris Cards */}
            <h2
              data-aos="fade-left"
              data-aos-duration="1000"
              className={GlobalStyle.header}
            >
              Why Choose Clyveris Cards
            </h2>
            <ul className="list-disc list-inside w-full">
              <li className={GlobalStyle.text}>
                Contactless payments for safety and convenience
              </li>
              <li className={GlobalStyle.text}>
                Accepted worldwide wherever major cards are recognized
              </li>
              <li className={GlobalStyle.text}>
                Advanced security features to protect against fraud
              </li>
            </ul>
            <p className={GlobalStyle.textSmallItalic}>
              Find the perfect card for your lifestyle.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCards;
