import React, { useState } from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import personal from "../../../images/loanImages/personal.png";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "How long does it take to get approved?",
    answer: "Most personal loans are approved within 24-48 hours.",
  },
  {
    question: "Is there a penalty for early repayment?",
    answer: "No, you can repay your loan early without penalties.",
  },
];

const PersonalLoans = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to={`/`}>Home</Link>
            <PiCaretRightThin className="text-primary" />
            <Link to={`/loans`}>Loans</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Personal Loans</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Personal Loans</h1>
          <span className={GlobalStyle.textSmallItalic}>
            From vacations to emergencies, our personal loans are here to help
            you achieve your goals.
          </span>
          <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
            <img
              data-aos="fade-right"
              data-aos-duration="1000"
              src={personal}
              alt=""
              className="w-full"
            />
            <div className="w-full flex flex-col gap-5">
              <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Loan amounts ranging from $1,000 to $50,000.
                </li>
                <li className={GlobalStyle.text}>No collateral required.</li>
                <li className={GlobalStyle.text}>
                  Fixed monthly payments and competitive rates.
                </li>
                <li className={GlobalStyle.text}>
                  Quick and easy approval process.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>How to Apply</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Log in to your Clyveris Bank account.
                </li>
                <li className={GlobalStyle.text}>
                  Select "Personal Loans" and complete the online application.
                </li>
                <li className={GlobalStyle.text}>
                  Get approved and access funds quickly.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>Eligibility</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>Consolidate debt.</li>
                <li className={GlobalStyle.text}>
                  Fund a major purchase or home improvement.
                </li>
                <li className={GlobalStyle.text}>Cover unexpected expenses.</li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <div className="w-full text-center my-2">
              <h4 className={GlobalStyle.textPrimaryBold}>FAQ's</h4>
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b mb-4">
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left p-4 text-sm font-medium focus:outline-none flex justify-between items-center ${
                    activeIndex === index ? "text-primary" : "text-gray-500"
                  }`}
                >
                  <span>{faq.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="p-4 text-gray-600 text-xs">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <Link className={GlobalStyle.buttonSmall}>Apply now</Link>
          <p className={GlobalStyle.textSmallItalic}>
            Your dream home is just a few steps away.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalLoans;
