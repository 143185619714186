import React, { useState } from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import education from "../../../images/loanImages/educational.png";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "When do I start repaying my loan?",
    answer: "Repayment typically begins 6 months after graduation.",
  },
  {
    question: "Can I refinance my educational loan later?",
    answer:
      "Yes, refinancing options are available to lower your interest rate.",
  },
];

const EducationalLoan = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to={`/`}>Home</Link>
            <PiCaretRightThin className="text-primary" />
            <Link to={`/loans`}>Loans</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Educational Loans</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Educational Loans</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Pursue higher education with Clyveris Bank’s affordable educational
            loans.
          </span>
          <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
            <img
              data-aos="fade-right"
              data-aos-duration="1000"
              src={education}
              alt=""
              className="w-full"
            />
            <div className="w-full flex flex-col gap-5">
              <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Competitive interest rates tailored for students.
                </li>
                <li className={GlobalStyle.text}>
                  Flexible repayment plans after graduation.
                </li>
                <li className={GlobalStyle.text}>
                  Covers tuition, books, and living expenses.
                </li>
                <li className={GlobalStyle.text}>No prepayment penalties.</li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>How to Apply</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Submit an online application.
                </li>
                <li className={GlobalStyle.text}>
                  Provide proof of enrollment and identification.
                </li>
                <li className={GlobalStyle.text}>
                  Receive approval and funds directly to your institution or
                  account.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>Eligibility</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Proof of enrollment in an accredited institution.
                </li>
                <li className={GlobalStyle.text}>Co-signer (if required).</li>
                <li className={GlobalStyle.text}>
                  Proof of income or financial need.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <div className="w-full text-center my-2">
              <h4 className={GlobalStyle.textPrimaryBold}>FAQ's</h4>
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b mb-4">
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left p-4 text-sm font-medium focus:outline-none flex justify-between items-center ${
                    activeIndex === index ? "text-primary" : "text-gray-500"
                  }`}
                >
                  <span>{faq.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="p-4 text-gray-600 text-xs">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <Link className={GlobalStyle.buttonSmall}>Apply now</Link>
          <p className={GlobalStyle.textSmallItalic}>
            Take the next step toward your educational goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EducationalLoan;
