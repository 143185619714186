import React from "react";
import logo from "../../logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerContent = [
    {
      sectionTitle: "Quick Links",
      items: [
        { label: "Home", link: "/" },
        { label: "About Us", link: "/about-us" },
        { label: "Accounts", link: "/accounts" },
        { label: "Cards", link: "/cards" },
        { label: "FAQs", link: "/faqs" },
      ],
    },
    {
      sectionTitle: "Legal Information",
      items: [
        { label: "Privacy Policy", link: "/privacy-policy" },
        { label: "Terms and Conditions", link: "/terms-and-condition" },
      ],
    },
    {
      sectionTitle: "Contact Information",
      items: [
        { label: "Phone: 1-800-555-1234", link: "tel:18005551234" },
        {
          label: "Email: support@clyverisbank.com",
          link: "mailto:support@clyverisbank.com",
        },
        {
          label: "Address: 1234 Banking Blvd, Cityname, State, ZIP",
          link: "https://maps.google.com/?q=1234+Banking+Blvd,Cityname,State,ZIP",
        },
      ],
    },
  ];
  return (
    <footer className="w-full p-10 bg-gray-900 fle flex-col">
      <div className="w-full flex gap-10 max-md:flex-col">
        <div className="w-full lg:w-1/2 flex flex-col gap-3">
          <Link to={"/"} className="flex items-start gap-2">
            <img
              data-aos="zoom-in-right"
              src={logo}
              alt="Logo"
              className="w-10 h-10"
            />
            <span className="text-xl lg:text-lg text-white">Clyveris Bank</span>
          </Link>
          <p className="text-white text-sm font-extralight text-justify">
            Clyveris Bank - Your trusted partner in personal banking. Offering
            secure checking and savings accounts, seamless fund transfers, and
            24/7 support to help you manage your finances with ease.
          </p>
        </div>
        {footerContent.map((item, index) => {
          return (
            <div key={index} className="flex flex-col gap-1">
              <h2 className="text-xl text-white mb-2">{item.sectionTitle}</h2>
              {item.items.map((item, idx) => (
                <Link
                  key={idx}
                  className="text-white text-sm font-extralight"
                  to={item.link}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          );
        })}
      </div>
      <div className="text-xs text-white text-center w-full font-extralight mt-5">
        © {new Date().getFullYear()} Clyveris Bank. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
