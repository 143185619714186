import React from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import SettingsInfo from "../../components/settingsComponents/SettingsInfo";
import SettingsAddress from "../../components/settingsComponents/SettingsAddress";

const Settings = () => {
  const user = useSelector((state) => state.user.profile);
  const account = useSelector((state) => state.account.items);
  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Settings</h2>
        <div className="w-full flex max-lg:flex-col gap-5">
          <SettingsInfo user={user} account={account} />
          <SettingsAddress user={user} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
