import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo.png";
import api from "../../api/api";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { useSelector } from "react-redux";

const SetNewPassword = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const auth = useSelector((state) => state.user.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = sessionStorage.getItem("email");
    if (savedEmail) {
      setEmail(savedEmail);
    }
    // eslint-disable-next-line
  }, []);

  // Password requirements
  const passwordRequirements = [
    { id: 1, text: "At least 8 characters", valid: password.length >= 8 },
    {
      id: 2,
      text: "At least one uppercase letter",
      valid: /[A-Z]/.test(password),
    },
    { id: 3, text: "At least one number", valid: /[0-9]/.test(password) },
    {
      id: 4,
      text: "At least one special character",
      valid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    {
      id: 5,
      text: "Passwords match",
      valid: password === password_confirmation && password !== "",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (otp === "" || password === "" || password_confirmation === "") {
      toast.error("Please fill all the fields");
      return;
    }

    if (!passwordRequirements.every((req) => req.valid)) {
      toast.error("Please meet all password requirements.");
      return;
    }

    setLoading(true);
    try {
      const res = await api.post("/verify-otp-and-reset-password", {
        otp,
        email,
        password,
        password_confirmation,
      });
      toast.success(res.data.message);
      if (auth) {
        navigate("/online-banking/settings");
      } else {
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={GlobalStyle.containerCenter}>
      <div className={GlobalStyle.centerWrap}>
        <form className={GlobalStyle.form2} onSubmit={handleSubmit}>
          <div className="flex w-full justify-between items-center">
            <h2 className={GlobalStyle.header}>Reset password</h2>
            <img src={logo} alt="logo" className="w-16 h-16" />
          </div>
          <input
            type="number"
            className={GlobalStyle.input3}
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            className={GlobalStyle.input3}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            className={GlobalStyle.input3}
            placeholder="Confirm password"
            value={password_confirmation}
            onChange={(e) => setPassword_confirmation(e.target.value)}
          />
          <div className="w-full flex justify-end text-gray-500">
            {hidden ? (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p>Show password</p>
                <FaRegEye />
              </div>
            ) : (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p>Hide password</p>
                <FaRegEyeSlash />
              </div>
            )}
          </div>

          {/* Password Requirements */}
          <div className="w-full text-xs">
            <p className="text-gray-600 mb-1">
              Password must meet the following:
            </p>
            <ul>
              {passwordRequirements.map((req) => (
                <li
                  key={req.id}
                  className={`mb-1 ${
                    req.valid ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {req.text}
                </li>
              ))}
            </ul>
          </div>

          <button className={GlobalStyle.buttonFull}>
            {loading ? <LoadingSpinner /> : "Reset"}
          </button>
          <Link
            className={GlobalStyle.linkSmall}
            to={"/reset-account-password"}
          >
            Back
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SetNewPassword;
