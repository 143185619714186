import React from "react";
import PrimaryNavbar from "../components/navbar/PrimaryNavbar";
import Footer from "../components/footer/Footer";
import ScrollToTop from "../components/topScroll/ScrollToTop";

const PrimaryLayout = ({ children }) => {
  return (
    <>
      <PrimaryNavbar />
      <ScrollToTop />
      {children}
      <Footer />
    </>
  );
};

export default PrimaryLayout;
