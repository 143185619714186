import React, { useEffect, useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import api from "../../api/api";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { Link } from "react-router-dom";

const AllUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await api.get("/all-users");
      setUsers(res.data);
      setFilteredUsers(res.data); // Initialize filtered users
    } catch (error) {
      toast.error("Error retrieving users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = users.filter(
      (user) =>
        user.firstname.toLowerCase().includes(lowerCaseQuery) ||
        user.lastname.toLowerCase().includes(lowerCaseQuery) ||
        user.email.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const sortRole = (role) => {
    return role === "admin" ? "A" : "U";
  };

  if (loading) {
    return (
      <div className={GlobalStyle.containerAdmin}>
        <div className={GlobalStyle.adminWrap2}>
          <div className="w-full h-full flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap2}>
        <h2 className={GlobalStyle.header}>Manage users</h2>
        <div className="w-full mt-5">
          <div className={GlobalStyle.sectionContainer}>
            <div className="w-full">
              <h3 className={GlobalStyle.textPrimaryBold}>All users</h3>
            </div>
            <input
              type="text"
              className={GlobalStyle.input}
              placeholder="Search by name or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {filteredUsers && (
              <div className="w-full flex flex-col max-h-[400px] overflow-auto">
                <span className={GlobalStyle.textSmallPrimary}>
                  {filteredUsers.length} users
                </span>
                {filteredUsers
                  .slice()
                  .reverse()
                  .map((user) => (
                    <Link
                      key={user.id}
                      to={`/online-banking/admin/users/${user.id}`}
                      className="w-full h-full py-2 hover:bg-primary/10 flex justify-between items-center gap-1 text-primary border-b border-primary/10 border-dashed text-xs md:text-sm lg:text-base"
                    >
                      <div className="w-full h-full flex items-center gap-1">
                        <div className="w-6 h-6 flex items-center justify-center bg-primary text-white rounded-full text-xs">
                          {sortRole(user.role)}
                        </div>
                        <span className="capitalize">
                          {user.firstname} {user.lastname}
                        </span>
                      </div>
                      <div className="w-full h-full flex justify-end items-center">
                        {user.email}
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsersPage;
