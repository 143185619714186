import React, { useState } from "react";
import LoadingSpinner from "../loader/LoadingSpinner";
import { formatDollar } from "../../global/formatDollar";
import { BsPiggyBank } from "react-icons/bs";
import { CiMoneyCheck1 } from "react-icons/ci";
import GlobalStyle from "../../global/GlobalStyle";
import api from "../../api/api";
import toast from "react-hot-toast";

const UsersAccounts = ({ user, accounts, loading, getUser }) => {
  const [amount, setAmount] = useState("");
  const [months, setMonths] = useState("");
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteAccount = async (id) => {
    setLoadingDelete(true);
    try {
      const res = await api.post(`/delete-account/${id}`);
      toast.success(res.data.message);
      getUser();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
    }
  };

  const generateTransactions = async (e, accountId) => {
    e.preventDefault();
    if (!amount || !months) {
      toast.error("Amount and months are required.");
      return;
    }

    setLoadingGenerate(true);
    try {
      const res = await api.post("/generate-transactions", {
        account_id: accountId,
        amount: parseFloat(amount),
        months: parseInt(months),
        user_id: user.id,
      });
      toast.success(res.data.message);
      setAmount("");
      setMonths("");
      setLoadingGenerate(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error generating transactions."
      );
      console.log(error);
      setLoadingGenerate(false);
    } finally {
      setTimeout(() => {
        getUser();
      }, 3000);
    }
  };

  if (loading) {
    return (
      <div className="w-full flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (accounts.length < 1) {
    return (
      <span className="w-full flex justify-center">
        <span className="px-5 py-1 rounded-3xl bg-white dark:bg-primary/10 shadow transition-all ease-in-out duration-1000">
          <span className={GlobalStyle.textSmallPrimary}>
            No accounts yet for this user.
          </span>
        </span>
      </span>
    );
  }

  return (
    <>
      {accounts && (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {accounts.map((account, index) => (
            <div className={GlobalStyle.accountContainer} key={index}>
              <span className="flex items-center gap-2">
                <h3 className={GlobalStyle.textPrimaryCapital}>
                  {account.account_type} account
                </h3>
                {account.account_type === "savings" ? (
                  <BsPiggyBank className="text-xl text-primary" />
                ) : (
                  <CiMoneyCheck1 className="text-xl text-primary" />
                )}
              </span>
              <span className={GlobalStyle.textSmall}>
                Account Number: {account.account_number}
              </span>
              <span className={GlobalStyle.textSmall}>
                Routing Number: {account.routing_number}
              </span>
              <span className={GlobalStyle.textSmall}>
                Balance:{" "}
                <span className="font-mono">
                  {formatDollar(account.balance)}
                </span>
              </span>
              <div className="w-full text-center">
                <span className={GlobalStyle.textPrimaryCapital}>
                  Generate history
                </span>
              </div>
              <form
                className="w-full flex flex-col gap-2"
                onSubmit={(e) => generateTransactions(e, account.id)}
              >
                <input
                  type="number"
                  placeholder="Amount"
                  className={GlobalStyle.input}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Number of months"
                  className={GlobalStyle.input}
                  value={months}
                  onChange={(e) => setMonths(e.target.value)}
                />
                <button
                  type="submit"
                  className={GlobalStyle.buttonFull}
                  disabled={loadingGenerate}
                >
                  {loadingGenerate ? "Generating..." : "Generate"}
                </button>
              </form>
              <div className="w-full text-center">
                <span className={GlobalStyle.textPrimaryCapital}>
                  Delete account
                </span>
              </div>
              <button
                onClick={() => deleteAccount(account.id)}
                className={GlobalStyle.buttonFull}
              >
                {loadingDelete ? "Deleting..." : "Delete account"}
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UsersAccounts;
