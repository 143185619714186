import React, { useState } from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import credit from "../../../images/homeImages/ccard.png";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "What’s the interest rate on your credit cards?",
    answer:
      "Interest rates start as low as 15.99% APR based on creditworthiness.",
  },
  {
    question: "Can I request a credit limit increase?",
    answer:
      "Yes, you can request a credit limit increase through your account dashboard.",
  },
];

const CreditCard = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to={`/`}>Home</Link>
            <PiCaretRightThin className="text-primary" />
            <Link to={`/cards`}>Cards</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Credit Card</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Credit Cards</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Build your credit and enjoy exclusive perks with Clyveris Bank
            Credit Cards.
          </span>
          <div className="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
            <img
              data-aos="fade-right"
              data-aos-duration="1000"
              src={credit}
              alt="Credit Card"
              className="w-full shadow-lg"
            />
            <div className="w-full flex flex-col gap-5">
              <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Competitive interest rates.
                </li>
                <li className={GlobalStyle.text}>
                  Earn cashback and rewards on purchases.
                </li>
                <li className={GlobalStyle.text}>Flexible payment options.</li>
                <li className={GlobalStyle.text}>
                  No liability for unauthorized purchases.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>
                How to Get Your Debit
              </h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Log in to your Clyveris Bank account or visit a branch.
                </li>
                <li className={GlobalStyle.text}>
                  Complete the online credit card application.
                </li>
                <li className={GlobalStyle.text}>
                  Receive your card within 7-10 business days upon approval.
                </li>
              </ul>
              <h4 className={GlobalStyle.textPrimaryBold}>Security Benefits</h4>
              <ul className="list-inside list-decimal">
                <li className={GlobalStyle.text}>
                  Fraud monitoring and alerts.
                </li>
                <li className={GlobalStyle.text}>
                  EMV chip for secure transactions.
                </li>
                <li className={GlobalStyle.text}>
                  Virtual card options for online shopping.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <div className="w-full text-center my-2">
              <h4 className={GlobalStyle.textPrimaryBold}>FAQ's</h4>
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b mb-4">
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left p-4 text-sm font-medium focus:outline-none flex justify-between items-center ${
                    activeIndex === index ? "text-primary" : "text-gray-500"
                  }`}
                >
                  <span>{faq.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 transform transition-transform ease-in-out duration-1000 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="p-4 text-gray-600 text-xs">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <p className={GlobalStyle.textSmallItalic}>
            Discover the credit card that meets your financial goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
