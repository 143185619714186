import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import api from "../../api/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import SetPinModal from "../../components/pin/SetPinModal";

const ValidatePassword = () => {
  const user = useSelector((state) => state.user.profile);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [showSetPinModal, setShowSetPinModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post("/validate-user-password", {
        user_id: user.id,
        password,
      });
      toast.success("Password verified. Proceed to set your PIN.");
      setPassword("");
      setShowSetPinModal(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "Invalid password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={GlobalStyle.containerAdmin}>
      <div className={GlobalStyle.adminWrap}>
        <h2 className={GlobalStyle.header}>Reset PIN</h2>
        <div className="w-full flex justify-center mt-5">
          <form
            className={GlobalStyle.sectionContainer3}
            onSubmit={handleSubmit}
          >
            <h3 className={GlobalStyle.textPrimaryCapital}>Verify Password</h3>
            <input
              type={hidden ? "password" : "text"}
              className={GlobalStyle.input}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="w-full flex justify-end text-gray-500">
              {hidden ? (
                <div
                  onClick={() => setHidden(!hidden)}
                  className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
                >
                  <p className="">Show password</p>
                  <FaRegEye />
                </div>
              ) : (
                <div
                  onClick={() => setHidden(!hidden)}
                  className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
                >
                  <p className="">Hide password</p>
                  <FaRegEyeSlash />
                </div>
              )}
            </div>
            <button className={GlobalStyle.buttonFull}>
              {loading ? <LoadingSpinner /> : "Verify"}
            </button>
          </form>
        </div>
      </div>
      <SetPinModal
        isOpen={showSetPinModal}
        onClose={() => setShowSetPinModal(false)}
      />
    </div>
  );
};

export default ValidatePassword;
