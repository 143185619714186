import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { FaCheck, FaRegCopy } from "react-icons/fa";
import { Link } from "react-router-dom";

const SettingsInfo = ({ user, account }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(user.banking_id).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2500);
    });
  };
  return (
    <div className={GlobalStyle.sectionContainer2}>
      <div className="w-full flex flex-col gap-1">
        <h3 className={GlobalStyle.textPrimaryBold}>Account Information</h3>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Name:</span>
          <span className="capitalize">
            {user.firstname} {user.lastname}
          </span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Email:</span>
          <span className="">{user.email}</span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Banking ID:</span>
          <button onClick={handleCopy} className="flex items-center gap-2">
            <span className="text-primary text-sm" aria-label="Copy Banking ID">
              {isCopied ? (
                <FaCheck className="text-green-500" />
              ) : (
                <FaRegCopy />
              )}
            </span>
            <span className="capitalize">{user.banking_id}</span>
          </button>
        </div>
        {account && (
          <div className={GlobalStyle.transactionModalColumn}>
            <span>Number of accounts:</span>
            <span className="capitalize">{account.length}</span>
          </div>
        )}
        <div className="w-full flex gap-5 mt-5">
          <Link
            to={`/online-banking/settings/reset-pin`}
            className={GlobalStyle.buttonFull}
          >
            Change pin
          </Link>
          <Link
            to={"/reset-account-password"}
            className={GlobalStyle.buttonFull}
          >
            Change password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SettingsInfo;
