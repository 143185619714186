import React from "react";
import SectionOne from "../components/homeSections/SectionOne";
import GlobalStyle from "../global/GlobalStyle";
import SectionTwo from "../components/homeSections/SectionTwo";
import SectionThree from "../components/homeSections/SectionThree";
import SectionFour from "../components/homeSections/SectionFour";
import SectionFive from "../components/homeSections/SectionFive";
import SectionSix from "../components/homeSections/SectionSix";

const Home = () => {
  return (
    <div className={GlobalStyle.container}>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
    </div>
  );
};

export default Home;
