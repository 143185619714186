import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import education from "../../../images/loanImages/educational.png";
import home from "../../../images/loanImages/Home.png";
import personal from "../../../images/loanImages/personal.png";
import { Link } from "react-router-dom";

const items = [
  {
    image: home,
    name: "Home Loans",
    description:
      "Find your perfect home with flexible loan options and competitive rates.",
    link: "/loans/home-loan",
  },
  {
    image: personal,
    name: "Personal Loans",
    description:
      "Achieve your personal goals with loans tailored to your needs.",
    link: "/loans/personal-loan",
  },
  {
    image: education,
    name: "Educational Loans",
    description:
      "Invest in your future with loans designed to cover educational expenses.",
    link: "/loans/educational-loan",
  },
];

const Loans = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to={`/`}>Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Loans</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Loans That Empower Your Dreams</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Whether it's a new home, personal goals, or education, Clyveris Bank
            is here to support you.
          </span>
          <div className="w-full lg:w-3/4 gap-5 lg:gap-10 grid grid-cols-1 lg:grid-cols-3">
            {items.map((item) => (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="w-full flex flex-col shadow-lg transition-all ease-in-out duration-1000 hover:shadow-2xl group relative"
              >
                <div className="w-full aspect-[1/1] flex items-center">
                  <img src={item.image} alt="null" className="w-full" />
                </div>
                <div className="w-full absolute top-2/3 bg-primary group-hover:bg-gradient-to-r group-hover:from-primary group-hover:to-black/30 text-white font-medium py-1 px-5 left-5 transition-all ease-in-out duration-1000 group-hover:w-2/3 shadow">
                  {item.name}
                </div>
                <div className="w-full p-5 flex flex-col gap-4 items-center mt-6">
                  <p className={GlobalStyle.text}>{item.description}</p>
                  <Link to={item.link} className={GlobalStyle.linkSmall}>
                    More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full lg:w-3/4 gap-5 lg:gap-10 flex flex-col">
            <h3
              data-aos="fade-right"
              data-aos-duration="1000"
              className={GlobalStyle.header}
            >
              Why Choose Clyveris Loans
            </h3>
            <ul className="list-disc list-inside">
              <li className={GlobalStyle.text}>Competitive interest rates</li>
              <li className={GlobalStyle.text}>Flexible repayment plans</li>
              <li className={GlobalStyle.text}>Fast approval process</li>
            </ul>
          </div>
          <span className={GlobalStyle.textSmallItalic}>
            Let’s turn your plans into reality.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loans;
