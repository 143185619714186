import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import checking from "../../../images/homeImages/checkings.png";
import savings from "../../../images/homeImages/savings.png";
import { Link } from "react-router-dom";

const AllAccounts = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Accounts</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Our Accounts</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Provide an overview of the two account types offered, with links to
            learn more about each.
          </span>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <p className={GlobalStyle.text}>
              Whether you're saving for the future or managing daily finances,
              we have the account that fits your goals.
            </p>
            <h2 className={GlobalStyle.textPrimaryBold}>Account Types</h2>
            <div className="w-full gap-5 lg:gap-10 grid grid-cols-1 md:grid-cols-2">
              <img
                data-aos="fade-right"
                data-aos-duration="1000"
                src={checking}
                alt=""
                className="w-full h-auto shadow-lg"
              />
              <div className="w-full flex flex-col gap-5">
                <h3
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className={GlobalStyle.header}
                >
                  Checking Account
                </h3>
                <p className={GlobalStyle.text}>
                  Perfect for managing everyday expenses with features like
                  debit cards, online banking, and seamless bill payments.
                </p>
                <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
                <ul className="list-inside list-decimal">
                  <li className={GlobalStyle.text}>
                    No monthly maintenance fees.
                  </li>
                  <li className={GlobalStyle.text}>
                    Unlimited transactions with no minimum balance requirement.
                  </li>
                  <li className={GlobalStyle.text}>
                    Access to online and mobile banking for quick and easy
                    payments.
                  </li>
                </ul>
                <Link
                  to={`/accounts/checking`}
                  className={GlobalStyle.buttonSmall}
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className="w-full gap-5 lg:gap-10 grid grid-cols-1 md:grid-cols-2">
              <img
                data-aos="fade-left"
                data-aos-duration="1000"
                src={savings}
                alt=""
                className="w-full h-auto shadow-lg md:col-start-2"
              />
              <div className="w-full flex flex-col gap-5 md:col-start-1 md:row-start-1">
                <h3
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className={GlobalStyle.header}
                >
                  Savings Account
                </h3>
                <p className={GlobalStyle.text}>
                  A secure way to grow your savings with competitive interest
                  rates and easy access when you need it.
                </p>
                <h4 className={GlobalStyle.textPrimaryBold}>Features</h4>
                <ul className="list-inside list-decimal">
                  <li className={GlobalStyle.text}>
                    Competitive interest rates to grow your money.
                  </li>
                  <li className={GlobalStyle.text}>
                    No withdrawal penalties for your first six transactions each
                    month.
                  </li>
                  <li className={GlobalStyle.text}>
                    Automatic savings tools to help you reach your financial
                    goals faster.
                  </li>
                </ul>
                <Link
                  to={`/accounts/savings`}
                  className={GlobalStyle.buttonSmall}
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAccounts;
