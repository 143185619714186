import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import { useSelector } from "react-redux";
import { FaRegCopy, FaCheck } from "react-icons/fa";

const DashboardInfo = () => {
  const user = useSelector((state) => state.user.profile);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(user.banking_id).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2500);
    });
  };

  return (
    <div className={GlobalStyle.sectionContainer}>
      <div className="w-full">
        <h3 className={GlobalStyle.textPrimaryBold}>Basic information</h3>
      </div>
      <div className="w-full flex flex-col gap-2 mt-2">
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Name:</span>
          <span className="capitalize">
            {user.firstname} {user.lastname}
          </span>
        </div>
        <div className={GlobalStyle.transactionModalColumn}>
          <span>Banking ID:</span>
          <button onClick={handleCopy} className="flex items-center gap-2">
            <span className="text-primary text-sm" aria-label="Copy Banking ID">
              {isCopied ? <FaCheck /> : <FaRegCopy />}
            </span>
            <span className="capitalize">{user.banking_id}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardInfo;
