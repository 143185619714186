import React, { useState } from "react";
import GlobalStyle from "../../global/GlobalStyle";
import logo from "../../logo.png";
import api from "../../api/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Signup = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();

  const user = {
    firstname,
    lastname,
    email,
    password,
    password_confirmation: confirmPassword,
  };

  const passwordRequirements = [
    { id: 1, text: "At least 8 characters", valid: password.length >= 8 },
    {
      id: 2,
      text: "At least one uppercase letter",
      valid: /[A-Z]/.test(password),
    },
    { id: 3, text: "At least one number", valid: /[0-9]/.test(password) },
    {
      id: 4,
      text: "At least one special character",
      valid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    {
      id: 5,
      text: "Passwords match",
      valid: password === confirmPassword && password !== "",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (!passwordRequirements.every((req) => req.valid)) {
      toast.error("Please meet all password requirements.");
      return;
    }

    setLoading(true);
    try {
      await api.post("/create-user", user);
      toast.success("Account successfully created");
      navigate("/login");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={GlobalStyle.containerCenter}>
      <div className={GlobalStyle.centerWrap}>
        <form className={GlobalStyle.form2} onSubmit={handleSubmit}>
          <div className="flex w-full justify-between items-center">
            <h2 className={GlobalStyle.header}>Create an Account</h2>
            <img src={logo} alt="logo" className="w-16 h-16" />
          </div>
          <div className="w-full gap-4 flex">
            <input
              type="text"
              className={GlobalStyle.input3}
              placeholder="First name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <input
              type="text"
              className={GlobalStyle.input3}
              placeholder="Last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <input
            type="text"
            className={GlobalStyle.input3}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            className={GlobalStyle.input3}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            className={GlobalStyle.input3}
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="w-full flex justify-end text-gray-500">
            {hidden ? (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <FaRegEye />
              </div>
            ) : (
              <div
                onClick={() => setHidden(!hidden)}
                className="flex items-center gap-1 text-secondary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <FaRegEyeSlash />
              </div>
            )}
          </div>

          {/* Password Requirements */}
          <div className="w-full text-xs">
            <p className="text-gray-600 mb-1">
              Password must meet the following:
            </p>
            <ul>
              {passwordRequirements.map((req) => (
                <li
                  key={req.id}
                  className={`mb-1 ${
                    req.valid ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {req.text}
                </li>
              ))}
            </ul>
          </div>

          <button className={GlobalStyle.buttonFull}>
            {loading ? <LoadingSpinner /> : "Sign up"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
