import React, { useEffect, useState } from "react";
import image1 from "../../images/1.jpeg";
import image2 from "../../images/2.jpeg";
import image3 from "../../images/3.jpeg";
import GlobalStyle from "../../global/GlobalStyle";
import { Link } from "react-router-dom";

const SectionOne = () => {
  // Array of content for the section (image, header, text, button text)
  const contentArray = [
    {
      image: image1,
      header: "Welcome to Clyveris Bank",
      text: "Your trusted partner in managing finances.",
      buttonText: "Get Started",
      link: "/login",
    },
    {
      image: image2,
      header: "Innovative Banking Solutions",
      text: "Explore our range of personalized services.",
      buttonText: "Learn More",
      link: "/about-us",
    },
    {
      image: image3,
      header: "Banking Made Easy",
      text: "Simple and secure ways to manage your money.",
      buttonText: "Sign In",
      link: "/login",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimate(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentArray.length);
    }, 10000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
    // eslint-disable-next-line
  }, []);

  const handleDotClick = (index) => {
    setAnimate(true);
    setCurrentIndex(index); // Set the current index manually
  };

  // Reset animation when content changes
  useEffect(() => {
    setAnimate(false); // Reset animation state when the index changes
    const timeout = setTimeout(() => setAnimate(true), 0); // Delay before triggering animation
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <section className="w-full h-screen flex justify-center relative">
      <div
        className="w-full h-full bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${contentArray[currentIndex].image})`,
        }}
      >
        <div
          className={`px-10 lg:px-20 pt-20 absolute w-full h-full flex flex-col gap-5 justify-center bg-gradient-to-r from-black to-black/70 text-white`}
        >
          <h2
            className={`text-4xl font-semibold mb-4 font-serif ${
              animate
                ? "transition-all duration-700 ease-in-out transform translate-y-0 opacity-100"
                : "transform translate-y-2 opacity-0"
            }`}
          >
            {contentArray[currentIndex].header}
          </h2>
          <p
            className={`text-lg mb-6 ${
              animate
                ? "transition-all duration-700 ease-in-out opacity-100"
                : "opacity-0"
            }`}
          >
            {contentArray[currentIndex].text}
          </p>
          <Link
            to={contentArray[currentIndex].link}
            className={GlobalStyle.buttonSmall}
          >
            {contentArray[currentIndex].buttonText}
          </Link>
        </div>
      </div>

      {/* Dots for manual switching */}
      <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex gap-2">
        {contentArray.map((_, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-10 h-1  ${
              currentIndex === index ? "bg-primary" : "bg-white"
            }`}
          />
        ))}
      </div>
    </section>
  );
};

export default SectionOne;
