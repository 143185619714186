import React from "react";
import GlobalStyle from "../../../global/GlobalStyle";
import { PiCaretRightThin } from "react-icons/pi";
import holder from "../../../images/holder.jpeg";
import { Link } from "react-router-dom";

const testimonies = [
  {
    name: "John P., Loan Officer",
    testimony:
      "Clyveris Bank has given me opportunities to grow both professionally and personally!",
  },
  {
    name: "Regina O., Software Developer",
    testimony:
      "I love working with a team that truly values innovation and customer satisfaction.",
  },
];

const CareerPage = () => {
  return (
    <div className={GlobalStyle.container}>
      <div className="w-full flex flex-col pt-20">
        <div className={GlobalStyle.breadContainter}>
          <div className={GlobalStyle.breadWrap}>
            <Link to="/">Home</Link>
            <PiCaretRightThin className="text-primary" />
            <span>Career</span>
          </div>
        </div>
        <div className="w-full flex flex-col p-10 items-center gap-5 lg:gap-10">
          <h1 className={GlobalStyle.header}>Your Career Starts Here</h1>
          <span className={GlobalStyle.textSmallItalic}>
            Be part of a team that’s redefining personal banking and making a
            difference.
          </span>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Why Join Clyveris Bank?</h2>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>
                  Growth Opportunities: Advance your career with training and
                  mentorship programs.
                </li>
                <li className={GlobalStyle.text}>
                  Inclusive Culture: We celebrate diversity and foster a
                  supportive work environment.
                </li>
                <li className={GlobalStyle.text}>
                  Impactful Work: Contribute to innovative projects that reshape
                  banking experiences.
                </li>
                <li className={GlobalStyle.text}>
                  Competitive Benefits: Comprehensive healthcare plans,
                  Retirement savings programs, Generous paid time off,
                  Performance bonuses.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Current Openings</h2>
              <h3 className={GlobalStyle.textPrimaryBold}>
                Customer Service Representative
              </h3>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>Location: Remote</li>
                <li className={GlobalStyle.text}>
                  Description: Help customers navigate our services and resolve
                  inquiries.
                </li>
              </ul>
              <h3 className={GlobalStyle.textPrimaryBold}>
                Software Developer
              </h3>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>Location: On-site</li>
                <li className={GlobalStyle.text}>
                  Description: Build innovative banking solutions using
                  cutting-edge technologies.
                </li>
              </ul>
              <h3 className={GlobalStyle.textPrimaryBold}>Loan Advisor</h3>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>Location: On-site</li>
                <li className={GlobalStyle.text}>
                  Assist customers in securing loans and achieving their
                  financial goals.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Life at Clyveris Bank</h2>
              <p className={GlobalStyle.text}>
                At Clyveris Bank, we prioritize employee well-being and
                encourage work-life balance. From team-building events to career
                development programs, we’re committed to your success and
                happiness.
              </p>
              <h3 className={GlobalStyle.textPrimaryBold}>
                Testimonials from employees
              </h3>
              <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                {testimonies.map((item) => (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="w-full flex gap-4 p-5 shadow rounded-xl bg-gray-50 mt-5"
                  >
                    <img
                      src={holder}
                      alt=""
                      className="w-14 h-14 border rounded-full"
                    />
                    <div className="w-full flex flex-col justify-between gap-3">
                      <span className="text-gray-400 italic text-sm">
                        "{item.testimony}"
                      </span>
                      <span className="w-full text-right text-primary text-sm">
                        - {item.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>
                What to Expect During the Hiring Process
              </h2>
              <ul className="list-inside list-disc">
                <li className={GlobalStyle.text}>
                  Apply Online: Submit your application for the role that best
                  suits you.
                </li>
                <li className={GlobalStyle.text}>
                  Initial Screening: Our team will review your qualifications
                  and contact you.
                </li>
                <li className={GlobalStyle.text}>
                  Interview Process: Meet with hiring managers to discuss your
                  skills and experiences.
                </li>
                <li className={GlobalStyle.text}>
                  Offer Stage: Successful candidates will receive an offer and
                  onboarding details.
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-3/4 flex flex-col gap-5 lg:gap-10">
            <div className="w-full gap-2 flex flex-col">
              <h2 className={GlobalStyle.headerSub}>Diversity & Inclusion</h2>
              <p className={GlobalStyle.text}>
                At Clyveris Bank, we’re committed to creating an inclusive
                workplace where everyone feels valued. We celebrate diverse
                perspectives and believe they drive innovation.
              </p>
            </div>
          </div>
          <span className={GlobalStyle.textSmallItalic}>
            Ready to make an impact? Join Clyveris Bank and grow with us.
          </span>
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
