import React, { useState } from "react";
import { useSelector } from "react-redux";
import GlobalStyle from "../../global/GlobalStyle";

const PinModal = ({
  pin,
  setPin,
  onSubmit,
  onCancel,
  loading,
  description = "Enter your 4-Digit PIN to confirm.",
}) => {
  const user = useSelector((state) => state.user.profile);
  const [error, setError] = useState(""); // State to hold error messages

  const handleKeypadClick = (value) => {
    if (value === "clear") {
      setPin("");
    } else if (value === "delete") {
      setPin((prev) => prev.slice(0, -1));
    } else if (pin.length < 4) {
      setPin((prev) => prev + value.toString());
    }
  };

  const handleFormSubmit = () => {
    // Check if the user is suspended
    if (parseInt(user.suspended) === 1) {
      setError("Your account is suspended. Please contact support.");
      setTimeout(() => {
        setError("");
        onCancel(); // Optionally reset or close the form
        setPin(""); // Clear the PIN input
      }, 5000);
      return;
    }

    if (pin !== user.pin) {
      setError("Incorrect PIN. Please try again.");
      setTimeout(() => {
        setError("");
      }, 2500);
      setPin("");
      return;
    }

    onSubmit();
  };

  return (
    <div className={GlobalStyle.modal}>
      <div className={GlobalStyle.pinContainer}>
        <h2 className={GlobalStyle.header}>PIN Authorization</h2>
        {error ? (
          <p className={GlobalStyle.textSmallPrimary}>{error}</p>
        ) : (
          <p className={GlobalStyle.textSmall}>{description}</p>
        )}
        <div className="flex justify-center gap-2">
          {Array(4)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className={`w-4 h-4 rounded-full ${
                  pin.length > index
                    ? "bg-primary"
                    : "bg-gray-300 dark:bg-primary/10"
                }`}
              ></div>
            ))}
        </div>
        <div className="grid grid-cols-3 gap-5 mt-4">
          {[...[1, 2, 3, 4, 5, 6, 7, 8, 9], "clear", 0, "delete"].map(
            (value, index) => (
              <button
                key={index}
                onClick={() => handleKeypadClick(value)}
                className={`p-3 w-12 h-12 rounded-full flex items-center justify-center text-gray-500 text-xs ${
                  value === "clear" || value === "delete"
                    ? "hover:text-primary/80 hover:bg-primary/10"
                    : "hover:text-primary hover:bg-primary/10"
                }`}
              >
                {value === "clear" ? "Clear" : value === "delete" ? "⌫" : value}
              </button>
            )
          )}
        </div>

        <div className="flex justify-between mt-4 gap-5">
          <button
            onClick={onCancel}
            className={GlobalStyle.buttonSmall}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleFormSubmit} // Use the handleFormSubmit function
            className={GlobalStyle.buttonSmall}
            disabled={loading || pin.length !== 4}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PinModal;
