import React, { useState } from "react";
import logo from "../../logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { TbPig } from "react-icons/tb";
import { FaRegCreditCard } from "react-icons/fa";
import { BiCoinStack, BiTransfer } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import GlobalStyle from "../../global/GlobalStyle";
import { RiAdminLine, RiCloseLine, RiMenu3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../features/userSlice";
import toast from "react-hot-toast";
import api from "../../api/api";
import DarkModeToggle from "../dakmode/DarkModeToggle";

const userMenu = [
  {
    name: "dashboard",
    link: "/online-banking/dashboard",
    icon: <MdOutlineDashboard />,
  },
  { name: "accounts", link: "/online-banking/accounts", icon: <TbPig /> },
  { name: "cards", link: "/online-banking/cards", icon: <FaRegCreditCard /> },
  {
    name: "transactions",
    link: "/online-banking/transactions",
    icon: <BiTransfer />,
  },
  { name: "transfer", link: "/online-banking/transfer", icon: <BiCoinStack /> },
  {
    name: "settings",
    link: "/online-banking/settings",
    icon: <IoSettingsOutline />,
  },
];
const adminMenu = [
  {
    name: "dashboard",
    link: "/online-banking/dashboard",
    icon: <MdOutlineDashboard />,
  },
  { name: "accounts", link: "/online-banking/accounts", icon: <TbPig /> },
  { name: "cards", link: "/online-banking/cards", icon: <FaRegCreditCard /> },
  {
    name: "transactions",
    link: "/online-banking/transactions",
    icon: <BiTransfer />,
  },
  { name: "transfer", link: "/online-banking/transfer", icon: <BiCoinStack /> },
  { name: "Admin", link: "/online-banking/admin/users", icon: <RiAdminLine /> },
  {
    name: "settings",
    link: "/online-banking/settings",
    icon: <IoSettingsOutline />,
  },
];
const DashboardNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const user = useSelector((state) => state.user.profile);
  const menu = user.role === "admin" ? adminMenu : userMenu;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = async () => {
    const logoutPromise = api
      .post("/logout")
      .then(() => {
        dispatch(removeUser());
        localStorage.removeItem("access_token");
        navigate("/");
      })
      .catch((error) => {
        dispatch(removeUser());
        localStorage.removeItem("access_token");
        navigate("/");
      });

    toast.promise(logoutPromise, {
      loading: "Logging out...",
      success: "Logout successful!",
      error: "Logout failed. Please try again.",
    });
  };

  return (
    <nav className="w-full h-20 fixed z-30">
      <div className="w-full h-full flex justify-between items-center bg-white dark:bg-slate-900 dark:text-primary transition-all ease-in-out duration-1000 px-5 lg:px-10 relative">
        <Link to={"/"} className="flex items-center gap-2">
          <img src={logo} alt="Logo" className="w-10 h-10" />
          <span className="text-lg lg:text-lgl font-light">Clyveris Bank</span>
        </Link>
        <div className="flex items-center gap-5">
          <DarkModeToggle />
          <div
            className="lg:hidden text-primary text-2xl cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <RiCloseLine /> : <RiMenu3Line />}
          </div>
        </div>
        <div
          className={`w-64 h-[calc(100vh-5rem)] absolute left-0 top-full flex flex-col gap-2 transition-all ease-in-out duration-1000 bg-white dark:bg-slate-900 ${
            isOpen ? "max-lg:translate-x-0" : "max-lg:-translate-x-full"
          } p-3`}
        >
          {menu.map((items) => {
            return (
              <Link
                key={items.name}
                to={items.link}
                className={`w-full py-3 px-5 rounded-3xl flex items-center group gap-3 hover:bg-primary hover:text-white text-primary hover:scale-100 transition-all ease-in-out duration-1000 ${
                  location.pathname === items.link
                    ? "bg-primary/10"
                    : " scale-90"
                }`}
                onClick={() => setIsOpen(false)}
              >
                <div
                  className={` text-2xl ${
                    location.pathname === items.link
                      ? "text-primary group-hover:text-primary"
                      : "text-primary"
                  }`}
                >
                  {items.icon}
                </div>
                <span
                  className={`capitalize text-lg ${
                    location.pathname === items.link
                      ? "group-hover:text-white"
                      : ""
                  }`}
                >
                  {items.name}
                </span>
              </Link>
            );
          })}
          <button
            onClick={logoutUser}
            className={GlobalStyle.buttonFullAlternate}
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
